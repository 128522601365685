






























































































import Component from 'vue-class-component'
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import SygniTable from "@/components/table/SygniTable.vue";
import GenproxModal from "@/components/layout/GenproxModal.vue";
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import SygniCheckbox from "@/components/inputs/SygniCheckbox.vue";
import UserPresentation from "@/components/UserPresentation.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniSquareButton from "@/components/buttons/SygniSquareButton.vue";
import SygniRectButton from "@/components/buttons/SygniRectButton.vue";
import SygniArrowButton from "@/components/buttons/SygniArrowButton.vue";
import NetworksManagementModal from '@/modules/accounting/components/NetworksManagementModal.vue';
import { BSpinner, BTable, BvTableField } from "bootstrap-vue";
import SygniCircleButton from "@/components/buttons/SygniCircleStatusButton.vue";
import SygniNetworkPicker from '@/components/inputs/SygniNetworkPicker.vue';
import { Watch } from 'vue-property-decorator';
import breakpoints from '@/plugins/breakpoints';
import { mapGetters } from 'vuex';
import { Dictionaries } from '../../portfolio/store/types';
import { CheckboxOption } from '@/store/types';

@Component({
  components: {
    SygniCircleButton, SygniArrowButton, SygniRoundedButton, NetworksManagementModal, UserPresentation, ExpandCollapse, BSpinner, SygniSelect, SygniCheckbox, SygniNetworkPicker, SygniSquareButton, SygniRectButton, GenproxModal
  },
  computed: {
    ...mapGetters('portfolio', {
      dictionaries: 'getDictionaries',
    })
  }
})
export default class AdvisorsTable extends SygniTable<any> {
  showNetworkManagementModal: boolean = false;
  showedBrokerId: string | null = null;
  showNetworkAccessModal: boolean = false;
  bulkRows: Array<CheckboxOption> = [];
  selectAllRowsBoolean: boolean | null = false;
  deletingAdvisorId: string | null = null;
  dictionaries!: Dictionaries;
  refresh: any = null;

  breakpoints = breakpoints;

  tableFields: (BvTableField & { borderless?: Boolean } & { key: string })[] = [
    // { key: 'selected', sortable: false, borderless: true, label: '' },
    { key: 'firstName', sortable: false, borderless: false, class: 'table__head-cell left', label: 'Advisor name' },
    { key: 'email', sortable: true, borderless: false, class: 'table__head-cell left', label: 'Advisor email' },
    { key: 'lastLogin', sortable: true, borderless: false, class: 'table__head-cell center', label: 'Last login' },
    { key: 'networks', sortable: false, borderless: false, class: 'table__head-cell networks', label: 'Networks' },
    { key: 'status', sortable: true, class: 'table__head-cell', label: 'Status' },
    { key: 'actions', sortable: false, class: 'table__head-cell', label: 'Actions' },
  ];

  tableItems: any = [
    {
      advisorName: "Advisor no. 1",
      id: 1,
      flags: [],
      advisorStatus: 'INVITED',
      actions: [],
    },
    {
      advisorName: "Advisor no. 2",
      id: 2,
      flags: [],
      advisorStatus: 'ACTIVE',
      actions: [],
    },
    {
      advisorName: "Advisor no. 3",
      id: 3,
      flags: [],
      advisorStatus: 'ACTIVE',
      actions: [],
    },
    {
      advisorName: "Advisor no. 4",
      id: 4,
      flags: [],
      advisorStatus: 'REJECTED',
      actions: [],
    },
  ];

  get brokerData() {
    return this.items.find((el: any) => el.id === this.showedBrokerId) || []
  }

  get isMarketplaceAccountant() {
    return this.activeUserData?.role?.marketplace === 'accountant' ? true : false;
  }

  openDeleteConfirmationModal(advisorId: string) {
    this.deletingAdvisorId = advisorId;
  }

  closeDeleteConfirmationModal() {
    this.deletingAdvisorId = null;
  }

  async resendActivationEmail(item: any) {
    this.$store.commit(this.setTableBusyMutation, true)
    try {
      await this.$store.dispatch('advisors/resendActivationEmail', item?.id)
      this.$notify({
        type: 'success',
        title: 'Success',
        text: 'Email has been resent'
      })
    } catch (e) {
      const errorMessage = this.$options.filters.errorHandler(e);
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: this.$t(errorMessage).toString()
      });
    }
    this.$store.commit(this.setTableBusyMutation, false)
  }

  async deleteAdvisor() {
    try {
      this.$store.commit(this.setTableBusyMutation, true);
      await this.$store.dispatch('advisors/deleteBroker', this.deletingAdvisorId);
      await this.refreshTable();
      this.deletingAdvisorId = null;

      this.$notify({
        type: 'success',
        title: 'Success',
        text: 'Advisor deleted'
      })
    } catch(e) {
      const errorMessage = this.$options.filters.errorHandler(e);
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: this.$t(errorMessage).toString()
      });
    }
  }

  async activateAdvisor(advisorId: string) {
    try {
      this.$store.commit(this.setTableBusyMutation, true);
      await this.$store.dispatch('advisors/activateBroker', advisorId);
      await this.refreshTable();

      this.$notify({
        type: 'success',
        title: 'Success',
        text: 'Advisor activated'
      })
    } catch (e) {
      const errorMessage = this.$options.filters.errorHandler(e);
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: this.$t(errorMessage).toString()
      });
    }
  }

  enableNetworkManagementModal(id?: string) {
    this.showedBrokerId = id;
    this.showNetworkManagementModal = true;
  }

  closeNetworkManagementModal() {
    this.showedBrokerId = null;
    this.showNetworkManagementModal = false;
  }

  enableNetworkAccessModal(network: any) {
    this.sharingNetwork = network;
    this.showNetworkAccessModal = true;
  }

  closeNetworkAccessModal() {
    this.showNetworkAccessModal = false;
    this.sharingNetwork = null;
  }
  
  clearTableSelection() {
    this.selectAllRowsBoolean = false;
    this.bulkRows.filter(el => el.value !== undefined).forEach(row => {
      row.value = false;
    });
  }

  toggleAllRowsAction() {
    if (this.selectAllRowsBoolean === null) return;
    this.selectAllRowsBoolean = !this.selectAllRowsBoolean;

    if (this.selectAllRowsBoolean) {
      const selectedRowEl = (this.$refs.advisorsTable as BTable).$el.querySelector('.table tbody tr:nth-of-type(1)');
      this.bulkOptionsMarginTop = (selectedRowEl as HTMLDivElement).offsetTop + (selectedRowEl as HTMLDivElement).offsetHeight - 20;
      (this.$refs.advisorsTable as BTable).selectAllRows();
    } else {
      (this.$refs.advisorsTable as BTable).clearSelected();
    }

    this.bulkRows.forEach((row: any) => {
      row.value = this.selectAllRowsBoolean;
    });
  }

  toggleTableRow(item: any, index: number) {
    const selectedRowEl = (this.$refs.advisorsTable as BTable).$el.querySelector(`.table tbody tr:nth-of-type(${index + 1})`);
    this.bulkOptionsMarginTop = (selectedRowEl as HTMLDivElement).offsetTop + (selectedRowEl as HTMLDivElement).offsetHeight - 20;
    if (this.bulkRows[index]) {
      this.bulkRows[index].value = !this.bulkRows[index].value;
    }

    if (item.value) {
      (this.$refs.advisorsTable as BTable).selectRow(index);
    } else {
      (this.$refs.advisorsTable as BTable).unselectRow(index);
    }
  }

  resetCheckboxes(): void {
    this.bulkRows = [];
    this.tableItems.forEach((el: any, index: any) => {
      this.$set(this.bulkRows, index, { label: el.id, value: false });
    });
  }

  async refreshTable() {
    this.$store.commit(this.setTableBusyMutation, true);
    this.$nextTick(async () => {
      await this.getItems();
    })
  }

  getAdvisorStatusClassName(status: string): string {
    let className = 'success';
    switch(status.toUpperCase()) {
      case('ACTIVE'):
        className = 'primary';
        break;
      case('DELETED'):
        className = 'danger';
        break;
    }

    return className;
  }

  statusText(status: string): string {
    switch(status.toUpperCase()) {
      case('INITIAL'): {
        return 'Initial';
      }
      case('ACTIVE'): {
        return 'Active';
      }
      case('DELETED'): {
        return 'Deleted';
      }
    }
    return status;
  }

  onFiltersChange(filtersQuery?: string): void {
    this.$store.commit(this.setTableBusyMutation, true);
    this.$store.commit('advisors/setAdvisorsTableFiltersQuery', filtersQuery);
    if (this.paginationInstance) this.paginationInstance.$emit('changePage', 1);
    this.$nextTick(() => {
      const sign: string = this.sortDesc ? '-' : '';
      let sortBy: string = '';

      switch (this.sortBy) {
        default:
          sortBy = this.sortBy;
          break;
      }

      this.sortingQuery = {
        name: sortBy,
        order: sign,
      }
      this.$store.commit('advisors/setAdvisorsTableSortingQuery', this.sortingQuery);
      this.getItems();
    });
  }

  onSortChange(): void {
    this.$store.commit(this.setTableBusyMutation, true);
    if (this.paginationInstance) this.paginationInstance.$emit('changePage', 1);
    this.$nextTick(() => {
      const sign: string = this.sortDesc ? '-' : '';
      let sortBy: string = '';

      switch (this.sortBy) {
        default:
          sortBy = this.sortBy;
          break;
      }

      this.sortingQuery = {
        name: sortBy,
        order: sign,
      }
      this.$store.commit('advisors/setAdvisorsTableSortingQuery', this.sortingQuery);
      this.getItems();
    });
  }

  setQuery() {
    this.$store.commit('advisors/setAdvisorsTableQuery', this.localTableQuery);
  }

  getIndustryLabel(value: string) {
    if (!this.dictionaries) return value;

    const label = this.dictionaries.industry.find((el: any) => el.value == value)?.label;
    return label ? label : 'N/A';
  }

  getStageLabel(value: string) {
    if (!this.dictionaries) return value;

    const label = this.dictionaries.stage.find((el: any) => el.value == value)?.label;
    return label ? label : 'N/A';
  }

  getInstrumentClassLabel(value: string) {
    if (!this.dictionaries) return value;

    const label = this.dictionaries.instrumentClass.find((el: any) => el.value == value)?.label;
    return label ? label : 'N/A';
  }

  getInstrumentTypeLabel(value: string) {
    if (!this.dictionaries) return value;

    const label = this.dictionaries.instrumentType.find((el: any) => el.value == value)?.label;
    return label ? label : 'N/A';
  }

  get viewedCompanyId() {
    return this.$store.getters['auth/getViewedCompanyId'];
  }

  get viewedFundId() {
    return this.$store.getters['auth/getViewedFundId'];
  }

  get isPortfolioCompany() {
    return this.$route.path.includes('/company') ? true : false;
  }

  get viewedId() {
    if(this.isPortfolioCompany) {
      return this.activeUserData.context.context !== 'company' ? this.viewedCompanyId : '';
    } else {
      return this.activeUserData.context.context !== 'fund' ? this.viewedFundId : '';
    }
  }

  get rowsSelected() {
    const selectedRows = this.bulkRows.filter(el => el.value);

    return selectedRows;
  }

  get selectedRowsLength() {
    const selectedRows = this.bulkRows.filter(el => el.value);

    return selectedRows?.length;
  }

  async getItems() {
    await this.$store.dispatch(this.$attrs.getItemsAction, '');
    this.resetCheckboxes();
  }

  setWidths() {
    const columns: Array<number> = [];
    (this.$refs.advisorsTable as AdvisorsTable).$el.querySelectorAll('thead tr .table__head-cell').forEach((column: any) => {
      columns.push(column.offsetWidth);
    })
    this.$nextTick(() => {
      const dataRows = (this.$refs.advisorsTable as AdvisorsTable).$el.querySelectorAll('.b-table-details tbody tr');

      dataRows.forEach((dataRow: any) => {
        columns.forEach((width: any, index: number) => {
          if (index == 0) return;
          const element: HTMLElement = dataRow.querySelector(`td:nth-of-type(${index + 1})`);
          element.style.width = `${(index + 1) == columns.length ? width - 20 : width}px`;
        })
      })

    })
  }

  get filtersQuery() {
    return this.$store.getters['advisors/getAdvisorsTableFiltersQuery'];
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData'];
  }

  async beforeMount() {
    await this.getItems();
    this.onMounted();
    this.setBorderlessTds();
  }

  @Watch('selectAllRowsBoolean') onSelectAllRowsBooleanChange(): void {
    const selectedRowEl = (this.$refs.advisorsTable as BTable).$el.querySelector(`.table tbody tr:nth-of-type(1)`);
    this.bulkOptionsMarginTop = (selectedRowEl as HTMLDivElement).offsetTop + (selectedRowEl as HTMLDivElement).offsetHeight - 20;

    this.bulkRows.filter(el => el.value !== undefined).forEach(row => {
      row.value = this.selectAllRowsBoolean;
    });
  }

  @Watch('items') onItemsChange(): void {
    if (this.refresh) {
      clearTimeout(this.refresh);
      this.refresh = undefined;
    }

    if (this.isProcessing) {
      this.$store.commit('advisors/setAdvisorsTableBusy', true);
      this.refresh = setTimeout(() => {
        this.onFiltersChange(this.filtersQuery);
      }, 3000);
    }
  }

  @Watch('breakpoints.w') onBreakpointsChange(): void {
    this.setWidths();
  }
}

