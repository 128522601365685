


































































































































































































































































































































































































































































































































































































































































import Component, { mixins } from 'vue-class-component'
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniSquareButton from "@/components/buttons/SygniSquareButton.vue";
import SygniInput from "@/components/inputs/SygniInput.vue";
import SygniMultiSelect from '@/components/inputs/SygniMultiSelect.vue';
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import SygniDatePicker from "@/components/inputs/SygniDatePicker.vue";
import SygniRangeInput from "@/components/inputs/SygniRangeInput.vue";
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import SygniButtonsGroup from "@/components/layout/SygniButtonsGroup.vue";
import {SelectOption} from "@/store/types";
import {Filter, FilterFunctions, FilterMixin, FilterOperators} from "@/shared/mixins/FilterMixin";
import {AddProductSelects, InvestorSelects} from "@/modules/shared/dictionary-store/types";
import {mapGetters } from "vuex";
import {Statues} from "@/shared/interfaces/Statues";
import SygniCheckableButton from "@/components/buttons/SygniCheckableButton.vue";
import { Prop, Watch } from 'vue-property-decorator';
import { usedCurrencies } from '@/shared/consts';

@Component({
  components: {
    SygniCheckableButton,
    SygniRangeInput,
    SygniButtonsGroup,
    SygniMultiSelect,
    SygniDatePicker, SygniContainerTitle, SygniRoundedButton, SygniSquareButton, SygniSelect, SygniInput
  },
  computed: {
    ...mapGetters('dictionary', {
      investorSelects: 'investorsSelects',
      productSelects: 'addProductsSelects'
    })
  }
})
export default class MarketplaceFilters extends mixins (FilterMixin) {
  @Prop({default: 'investors'}) type: string;
  investors: SelectOption[] = [{value:'Adam', label: 'Adam'}, {value: 'Marek', label: 'Marek'}];
  investorSelects!: InvestorSelects;
  productSelects!: AddProductSelects;
  toggleFilters: boolean = false;
  usedCurrencies: any = usedCurrencies;
  selectedNetworkOptions: Array<any> = [];
  selectedInvestorStatuses: Array<any> = [];
  selectedProductStatuses: Array<any> = [];
  selectedAutentiStatuses: Array<any> = [];
  selectedSignedStatuses: Array<any> = [];
  selectedProductCurrencies: any[] = []
  readonly Statues = Statues;

  investorStatuses: any[] = [
    { label: 'Lead', value: 'LEAD' },
    { label: 'Invited', value: 'INVITED' },
    { label: 'Active', value: 'ACTIVE' },
    { label: 'Rejected', value: 'REJECTED' },
    { label: 'Declined', value: 'DECLINED' },
  ]

  productStatuses: any[] = [
    { label: 'Active', value: 'active' },
    { label: 'New', value: 'new' },
    { label: 'Declined', value: 'declined' },
    { label: 'Closed', value: 'closed' },
  ]

  toggleAllInvestorStatuses() {
    if (this.selectedInvestorStatuses.length < this.investorStatuses.length) {
      const statuses = this.investorStatuses.map(el => el.value);
      this.selectedInvestorStatuses = statuses;
      this.updateInvestorStatuses(statuses);
    } else {
      this.selectedInvestorStatuses = [];
      this.updateInvestorStatuses([]);
    }
  }

  updateInvestorStatuses(statuses: Array<string>) {
    this.filters.investmentClientStatus.value = statuses;
  }

  toggleAllProductStatuses() {
    if (this.selectedProductStatuses.length < this.productStatuses.length) {
      const statuses = this.productStatuses.map(el => el.value);
      this.selectedProductStatuses = statuses;
      this.updateProductStatuses(statuses);
    } else {
      this.selectedProductStatuses = [];
      this.updateProductStatuses([]);
    }
  }

  toggleAllAutentiStatuses() {
    if (this.selectedAutentiStatuses?.length < this.autentiStatuses?.length) {
      const statuses = this.autentiStatuses?.map((el: any) => el.value);
      this.selectedAutentiStatuses = statuses;
      this.updateAutentiStatuses(statuses);
    } else {
      this.selectedAutentiStatuses = [];
      this.updateAutentiStatuses([]);
    }
  }

  toggleAllSignedStatuses() {
    if (this.selectedSignedStatuses?.length < this.signedStatuses?.length) {
      const statuses = this.signedStatuses?.map((el: any) => el.value);
      this.selectedSignedStatuses = statuses;
      this.updateSignedStatuses(statuses);
    } else {
      this.selectedSignedStatuses = [];
      this.updateSignedStatuses([]);
    }
  }

  updateCurrencies(currencies: Array<string>) {
    if (this.type === 'payments') {
      this.filters.currency.value = currencies;
    } else {
      this.filters.productCurrency.value = currencies;
    }
  }

  toggleAllCurrencies() {
    if (this.selectedProductCurrencies.length < this.usedCurrencies.length) {
      const currencies = this.usedCurrencies.map((el: any) => el.value);
      this.selectedProductCurrencies = currencies;
      this.updateCurrencies(currencies);
    } else {
      this.selectedProductCurrencies = [];
      this.updateCurrencies([]);
    }
  }

  updateProductStatuses(statuses: Array<string>) {
    this.filters.productStatus.value = statuses;
  }

  updateAutentiStatuses(statuses: Array<string>) {
    this.filters.autentiStatus.value = statuses;
  }

  updateSignedStatuses(statuses: Array<string>) {
    this.filters.signedStatus.value = statuses;
  }

  get computedItemsUrl() {
    if(this.type === 'products') {
      return 'marketplace/legal-entity/products';
    }

    if(this.type === 'templates') {
      return 'marketplace/legal-entity/agreement/templates';
    }

    if(this.type === 'advisors') {
      return 'marketplace/legal-entity/brokers'
    }

    if(this.type === 'campaigns') {
      return 'marketplace/legal-entity/campaigns';
    }

    if(this.type === 'payments') {
      return 'marketplace/legal-entity/repayment-schedule';
    }

    if(this.type === 'clatTax') {
      return 'marketplace/legal-entity/product/clat-tax';
    }

    if (this.type === 'subscriptions') {
      return 'marketplace/legal-entity/subscription';
    }

    if (this.type === 'kyc') {
      return 'marketplace/legal-entity/kyc/investor-requests'
    }

    return 'marketplace/legal-entity/investment-client';
  }

  itemsUrl = 'marketplace/legal-entity/investment-client';

  filters: Record<string, Filter> = {
    investmentClientName: { value: null, getQueryValue: FilterFunctions.string , operator: FilterOperators.like, type: 'string' },
    investmentClient: { value: null, getQueryValue: FilterFunctions.string , operator: FilterOperators.like, type: 'string' },
    investmentClientInvestmentPotential: { value: null, getQueryValue: FilterFunctions.string , operator: FilterOperators.eq, type: 'string' },
    investmentClientSource: { value: null, getQueryValue: FilterFunctions.source , operator: FilterOperators.eq, type: 'string' },
    investmentClientStatus: { value: [], getQueryValue: FilterFunctions.array , operator: FilterOperators.in, type: 'string' },
    status: { value: [], getQueryValue: FilterFunctions.array , operator: FilterOperators.in, type: 'string' },
    productType: { value: null, getQueryValue: FilterFunctions.string , operator: FilterOperators.eq, type: 'string' },
    productCode: { value: null, getQueryValue: FilterFunctions.string , operator: FilterOperators.like, type: 'string' },
    productValue: { value: {from: null, to: null}, getQueryValue: FilterFunctions.range , operator: FilterOperators.like, type: 'range' },
    productAgreementDate: { value: null, getQueryValue: FilterFunctions.dateRange , operator: FilterOperators.like, type: 'dateRange' },
    productSource: { value: null, getQueryValue: FilterFunctions.source , operator: FilterOperators.eq, type: 'string' },
    productClass: { value: null, getQueryValue: FilterFunctions.string , operator: FilterOperators.like, type: 'string' },
    productStatus: { value: [], getQueryValue: FilterFunctions.array , operator: FilterOperators.in, type: 'string' },
    autentiStatus: { value: [], getQueryValue: FilterFunctions.array , operator: FilterOperators.in, type: 'string' },
    signedStatus: { value: [], getQueryValue: FilterFunctions.array , operator: FilterOperators.in, type: 'string' },
    isAttachment: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.eq, type: 'string' },
    paymentDate: { value: null, getQueryValue: FilterFunctions.dateRange , operator: FilterOperators.like, type: 'dateRange' },
    productPaymentDate: { value: null, getQueryValue: FilterFunctions.dateRange , operator: FilterOperators.like, type: 'dateRange' },
    interestPeriodFrom: { value: null, getQueryValue: FilterFunctions.dateRange , operator: FilterOperators.like, type: 'dateRange' },
    interestPeriodTo: { value: null, getQueryValue: FilterFunctions.dateRange , operator: FilterOperators.like, type: 'dateRange' },
    productInterestPeriodTo: { value: null, getQueryValue: FilterFunctions.dateRange , operator: FilterOperators.like, type: 'dateRange' },
    repaymentDate: { value: null, getQueryValue: FilterFunctions.dateRange , operator: FilterOperators.like, type: 'dateRange' },
    // status: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.eq, type: 'string' },
    createdAt: { value: null, getQueryValue: FilterFunctions.dateRange , operator: FilterOperators.like, type: 'dateRange' },
    updatedAt: { value: null, getQueryValue: FilterFunctions.dateRange , operator: FilterOperators.like, type: 'dateRange' },
    title: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.like, type: 'string' },
    authorName: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.like, type: 'string' },
    firstName: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.like, type: 'string' },
    surname: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.like, type: 'string' },
    email: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.like, type: 'string' },
    identificationNumber: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.like, type: 'string' },
    brokerNetworkIds: { value: [], getQueryValue: FilterFunctions.array, operator: FilterOperators.contain, type: 'string' },
    campaignName: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.like, type: 'string' },
    brokerName: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.like, type: 'string' },
    investorEmail: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.like, type: 'string' },
    startDate: { value: null, getQueryValue: FilterFunctions.dateRange , operator: FilterOperators.like, type: 'dateRange' },
    endDate: { value: null, getQueryValue: FilterFunctions.dateRange , operator: FilterOperators.like, type: 'dateRange' },
    startPowerOfAttorney: { value: null, getQueryValue: FilterFunctions.dateRange , operator: FilterOperators.like, type: 'dateRange' },
    endPowerOfAttorney: { value: null, getQueryValue: FilterFunctions.dateRange , operator: FilterOperators.like, type: 'dateRange' },
    lastLogin: { value: null, getQueryValue: FilterFunctions.dateRange , operator: FilterOperators.like, type: 'dateRange' },
    investmentValue: { value: { from: null, to: null }, getQueryValue: FilterFunctions.range, operator: FilterOperators.like, type: 'range' },
    agreementDate: { value: null, getQueryValue: FilterFunctions.dateRange , operator: FilterOperators.like, type: 'dateRange' },
    paymentType: { value: null, getQueryValue: FilterFunctions.string , operator: FilterOperators.eq, type: 'string' },
    interestAmountNet: { value: { from: null, to: null }, getQueryValue: FilterFunctions.range, operator: FilterOperators.like, type: 'range' },
    interestAmountGross: { value: { from: null, to: null }, getQueryValue: FilterFunctions.range, operator: FilterOperators.like, type: 'range' },
    interestTax: { value: { from: null, to: null }, getQueryValue: FilterFunctions.range, operator: FilterOperators.like, type: 'range' },
    currency: { value: [], getQueryValue: FilterFunctions.array , operator: FilterOperators.in, type: 'string' },
    productCurrency: { value: [], getQueryValue: FilterFunctions.array , operator: FilterOperators.in, type: 'string' },
    bankAccount: { value: null, getQueryValue: FilterFunctions.string , operator: FilterOperators.eq, type: 'string' },
    paymentStatus: { value: [], getQueryValue: FilterFunctions.array , operator: FilterOperators.in, type: 'string' },
    autentiEnabled: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.eq, type: 'string' },
    // clat tax
    value: { value: { from: null, to: null }, getQueryValue: FilterFunctions.range, operator: FilterOperators.like, type: 'range' },
    paymentDeadline: { value: null, getQueryValue: FilterFunctions.dateRange, operator: FilterOperators.like, type: 'dateRange' },
    investor: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.like, type: 'string' },
    // subscriptions
    code: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.like, type: 'string' },
    sourceName: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.like, type: 'string' },
    investmentReach: { value: { from: null, to: null }, getQueryValue: FilterFunctions.range, operator: FilterOperators.like, type: 'range' },
    capitalValue: { value: { from: null, to: null }, getQueryValue: FilterFunctions.range, operator: FilterOperators.like, type: 'range' },
    statementGenerated: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.eq, type: 'string' },
    repaymentScheduleGenerated: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.eq, type: 'string' },
    registrationStatus: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.eq, type: 'string' },
  };

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData']
  }

  get hasIssuerId() {
    return !!(this.activeUserData?.issuerManagerId)
  }

  get networks() {
    return this.$store.getters['genprox/networks']
  }

  get networkOptions() {
    return this.networks.map((el: any) => {
      return { label: el.name, value: el.id }
    })
  }

  get autentiStatuses() {
    return this.$store.getters['templates/getAutentiDictionaries']?.statuses
  }

  get signedStatuses() {
    return [
      {
        label: 'New',
        value: 'new',
      },
      {
        label: 'E-signed',
        value: 'e-signed',
      },
      {
        label: 'Sent',
        value: 'sent',
      },
      {
        label: 'Completed',
        value: 'completed',
      },
    ]
  }

  updateNetworks(ids: Array<string>) {
    this.filters.brokerNetworkIds.value = ids;
  }

  updateNetworksSingular(id: string) {
    this.filters.brokerNetworkIds.value = [id];
  }

  toggleAllNetworks() {
    if (this.selectedNetworkOptions.length < this.networkOptions.length) {
      const ids = this.networkOptions.map((el: any) => el.value);
      this.selectedNetworkOptions = ids;
      this.updateNetworks(ids);
    } else {
      this.selectedNetworkOptions = [];
      this.updateNetworks([]);
    }
  }

  get isLlc() {
    return this.activeUserData?.equityType !== 'JOINT_STOCK_SHARES'
  }

  get typeOptions() {
    return [
      { label: 'Template', value: 'false' },
      { label: 'Attachment', value: 'true' }
    ]
  }

  get autentiOptions() {
    return [
      { label: 'Enabled', value: 'true' }, 
      { label: 'Disabled', value: 'false' }
    ]
  }

  get hasAutenti() {
    return this.activeUserData?.autenti?.enabled
  }

  get toggleText(): string {
    return this.toggleFilters ? 'Show' : 'Hide';
  }

  get paymentDictionaries() {
    return this.$store.getters['products/getDictionary'] ? this.$store.getters['products/getDictionary'] : {};
  }

  handleFiltersToggle(): void {
    this.toggleFilters = !this.toggleFilters;
  }

  async applyFilters() {
    if(this.type === 'products' || this.type === 'templates' || this.type == 'payments' || this.type == 'clatTax' || this.type === 'campaigns' || this.type == 'advisors' || this.type === 'subscriptions') {

      if (this.type === 'payments' && this.$route.query?.productCode) {
        this.filters.productCode.value = this.$route.query.productCode;
        await this.$router.replace({ path: `/${this.$route.path.includes('company') ? 'company' : 'fund'}/fundraising`, query: { page: 'payments' } });

        setTimeout(() => {
          this.$emit('filtersChange', this.getFilterQuery());
        }, 1000);
      }

      if(this.isLoading) return;
      this.$emit('filtersChange', this.getFilterQuery());
    } else if (this.type === 'kyc') {
      this.$store.commit('investors/setKycTableBusy', true);
      this.$store.commit('investors/setKycTableQuery', { filters: this.getFilterQuery() });
      this.$store.dispatch('investors/getKyc');
      this.$emit('filtersChange', this.getFilterQuery());
    } else {
      this.$store.commit('investors/setInvestorsTableBusy', true);
      this.$store.commit('investors/setInvestorsQuery', { filters: this.getFilterQuery() });
      this.$store.commit('investors/setProductsQuery', this.getProductsQuery());
      this.$store.dispatch('investors/getInvestors');
      this.$emit('filtersChange', this.getFilterQuery());
    }
  }

  getProductsQuery(): string {
    let filterQueryTable: Array<any> = this.filterQuery.split('&');
    for(let i = 1; i < filterQueryTable.length; i++){
      if((filterQueryTable[i] as string).includes('investment_client')){
        filterQueryTable.splice(i,1);
        i--;
      }
    }
    let query = filterQueryTable.join('&');
    if(query[0] === '&'){
      query = '?' + query.slice(1,query.length);
    } else{
      query = '?' + query;
    }
    
    return query
  }

  createCustomQuery(filter: Filter, filterName: string): string {
    let query: string = '';
    let queryValue = filter.getQueryValue(filter.value);
    switch(filterName){
      case('investmentClientSource'): {
        if(this.filters.investmentClientSource.value === 'MARKETPLACE' ||
            this.filters.investmentClientSource.value === 'FUNDEQUATE' ){
          queryValue = filter.getQueryValue(filter.value);
          query += `&filters[investmentClientSourceType][0][value]=${queryValue}`;
          query += `&filters[investmentClientSourceType][0][operator]=${filter.operator}`;
        } else {
          queryValue = filter.getQueryValue(filter.value);
          query += `&filters[investmentClientSourceId][0][value]=${queryValue}`;
          query += `&filters[investmentClientSourceId][0][operator]=${filter.operator}`;
        }
        break;
      }
      case('productSource'): {
        if(this.filters.investmentClientSource.value === 'MARKETPLACE' ||
            this.filters.investmentClientSource.value === 'FUNDEQUATE' ){
          queryValue = filter.getQueryValue(filter.value);
          query += `&filters[productSourceType][0][value]=${queryValue}`;
          query += `&filters[productSourceType][0][operator]=${filter.operator}`;
        } else {
          queryValue = filter.getQueryValue(filter.value);
          query += `&filters[productSourceId][0][value]=${queryValue}`;
          query += `&filters[productSourceId][0][operator]=${filter.operator}`;
        }
      }
    }
    return query;
  }

  mounted(){
    this.onMounted();
  }

  clearAll() {
    for(let key in this.filters){
      if(Array.isArray(this.filters[key].value)){
        this.filters[key].value = [];
      } else if(this.filters[key].value?.to !== undefined ) {
        this.filters[key].value.to = '';
        this.filters[key].value.from = '';
      } else {
        this.filters[key].value = '';
      }
    }
    this.selectedNetworkOptions = []
    this.selectedInvestorStatuses = []
    this.selectedProductStatuses = []
    this.selectedProductCurrencies = []
    this.selectedAutentiStatuses = []
    this.selectedSignedStatuses = []
    this.applyFilters();
  }

  @Watch('type', { immediate: true }) onComputedItemsUrlChange() {
    this.itemsUrl = this.computedItemsUrl;
    this.clearAll();
  }
}
