








































































































































































































































import Component from 'vue-class-component'
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import SygniTextArea from '@/components/inputs/SygniTextArea.vue';
import UserPresentation from "@/components/UserPresentation.vue";
import SygniPagination from "@/components/table/SygniPagination.vue";
import InvestorsTable from "@/modules/genprox/modules/fund/modules/fundraising/components/InvestorsTable.vue";
import KycTable from "@/modules/genprox/modules/fund/modules/fundraising/components/KycTable.vue";
import InvestorsFilters from "@/modules/genprox/modules/fund/modules/fundraising/components/InvestorsFilters.vue";
import MarketplaceFilters from "@/modules/genprox/modules/fund/modules/fundraising/components/MarketplaceFilters.vue";
import RegisterationForm from "@/modules/auth/components/Register.vue";
import EmptyInvestorsTable from "@/modules/genprox/modules/fund/modules/fundraising/components/EmptyInvestorsTable.vue";
import ProductTable from "@/modules/genprox/modules/fund/modules/fundraising/modules/products/components/ProductTable.vue";
import ProductFilters from "@/modules/genprox/modules/fund/modules/fundraising/modules/products/components/ProductFilters.vue";
import AdvisorsTable from '@/modules/genprox/modules/fund/modules/fundraising/components/AdvisorsTable.vue';
import SygniTabs from '@/components/layout/SygniTabs.vue';
import SygniSelect from '@/components/inputs/SygniSelect.vue';
import AgreementsTable from '@/modules/genprox/components/wysiwyg/AgreementsTable.vue';
import {Investor} from "@/modules/genprox/modules/fund/modules/fundraising/store/types";
import {mapState} from "vuex";
import { TableData } from '@/modules/genprox/modules/profile/store/types';
import FlagsManagementModal from '@/modules/accounting/components/FlagsManagementModal.vue';
import SygniDatePicker from '@/components/inputs/SygniDatePicker.vue';
import SygniCheckbox from '@/components/inputs/SygniCheckbox.vue';
import SygniInput from '@/components/inputs/SygniInput.vue';
import SygniRadio from '@/components/inputs/SygniRadio.vue';
import FlagAccessModal from '@/modules/accounting/components/FlagAccessModal.vue';
import SygniModal from '@/components/layout/SygniModal.vue';
import GenproxModal from '@/components/layout/GenproxModal.vue';
import PaymentsTable from '../components/PaymentsTable.vue';
import ClatTaxTable from '../components/ClatTaxTable.vue';
import { Watch } from 'vue-property-decorator';
// import CampaignsBlindTable from '../components/CampaignsBlindTable.vue';
import CampaignsTable from '../components/CampaignsTable.vue';
import SubscriptionsTable from '../components/SubscriptionsTable.vue';
import CapitalRisePermissions from '../components/CapitalRisePermissions.vue';
import Utils from '@/modules/shared/utils/utils';
import GenproxDropdownMenu from '@/components/GenproxDropdownMenu.vue';
import { required, requiredIf } from 'vuelidate/lib/validators';
import { periodTypes, periodTypeOptions } from '@/shared/consts';
import GenproxPageHeading from '@/components/layout/GenproxPageHeading.vue';
import RecalculateSchedulesModal from '../components/RecalculateSchedulesModal.vue';
import GenproxInfobox from '@/components/layout/GenproxInfobox.vue';

Component.registerHooks(['validations'])
@Component({
  components: {
    GenproxDropdownMenu,
    GenproxPageHeading,
    AgreementsTable,
    EmptyInvestorsTable,
    RegisterationForm,
    InvestorsFilters,
    MarketplaceFilters,
    InvestorsTable,
    KycTable,
    ProductTable,
    ProductFilters,
    AdvisorsTable,
    SygniPagination,
    UserPresentation,
    SygniContainerTitle, SygniRoundedButton,
    SygniTabs,
    FlagsManagementModal,
    FlagAccessModal,
    CampaignsTable,
    SubscriptionsTable,
    PaymentsTable,
    ClatTaxTable,
    SygniModal,
    GenproxModal,
    SygniInput,
    SygniCheckbox,
    SygniDatePicker,
    SygniRadio,
    SygniSelect,
    SygniTextArea,
    RecalculateSchedulesModal,
    GenproxInfobox
  },
  computed: {
    ...mapState('investors', {
      investorsTableItems: (state: any) => state.investors,
      kycTableItems: (state: any) => state.kycTable,
      investorsTableIsBusy: (state: any) => state.investorsTableBusy,
      kycTableIsBusy: (state: any) => state.kycTableBusy,
      investorsTablePerPage: (state: any) => state.investorsTablePerPage,
      kycTablePerPage: (state: any) => state.kycTablePerPage,
    }),
    ...mapState('products', {
      productsTableItems: (state: any) => state.productsTable,
      productsTableIsBusy: (state: any) => state.productsTableIsBusy,
      scheduleTableItems: (state: any) => state.scheduleTable,
      scheduleTableIsBusy: (state: any) => state.scheduleTableIsBusy,
      schedulePerPage: (state: any) => state.scheduleTable.perPage,
      clatTaxTableItems: (state: any) => state.clatTaxTable,
      clatTaxTableIsBusy: (state: any) => state.clatTaxTableIsBusy,
      clatTaxPerPage: (state: any) => state.clatTaxTable.perPage,
    }),
    ...mapState('templates', {
      templatesTableItems: (state: any) => state.templatesTable,
      templatesTableIsBusy: (state: any) => state.templatesTableIsBusy,
      agreementsPerPage: (state: any) => state.templatesTable.perPage
    }),
    ...mapState('advisors', {
      advisorsTableItems: (state: any) => state.advisorsTable,
      advisorsTableIsBusy: (state: any) => state.advisorsTableIsBusy,
      advisorsPerPage: (state: any) => state.advisorsTable.perPage,
    }),
    ...mapState('subscriptions', {
      subscriptionsTableItems: (state: any) => state.subscriptionsTable,
      subscriptionsTableIsBusy: (state: any) => state.subscriptionsTableIsBusy,
      subscriptionsPerPage: (state: any) => state.subscriptionsTable.perPage,
    }),
    ...mapState('campaigns', {
      campaignsTableItems: (state: any) => state.campaignsTable,
      campaignsTableIsBusy: (state: any) => state.campaignsTableIsBusy,
      campaignsPerPage: (state: any) => state.campaignsTable.perPage,
    })
  },
})
export default class CapitalRiseModule extends CapitalRisePermissions {
  campaignsTableItems!: TableData<any>;
  templatesTableItems!: TableData<any>;
  advisorsTableItems!: TableData<any>;
  subscriptionsTableItems!: TableData<any>;
  productsTableItems!: TableData<any>;
  scheduleTableItems!: TableData<any>;
  clatTaxTableItems!: TableData<any>;
  campaignsTableIsBusy!: boolean;
  templatesTableIsBusy!: boolean;
  advisorsTableIsBusy!: boolean;
  subscriptionsTableIsBusy!: boolean;
  agreementsPerPage!: number;
  campaignsPerPage!: number;
  schedulePerPage!: number;
  clatTaxPerPage!: number;
  advisorsPerPage!: number;
  subscriptionsPerPage!: number;
  filtersQueryString = '';
  selectedTab: string = 'investors';
  productsTableIsBusy!: boolean;
  scheduleTableIsBusy!: boolean;
  clatTaxTableIsBusy!: boolean;
  investorsTableItems!: Investor[];
  kycTableItems!: any[];
  kycTableIsBusy!: boolean;
  kycTablePerPage!: number;
  investorsTableIsBusy!: boolean;
  investorsTablePerPage!: number;
  showFlagManagementModal: boolean = false;
  isCsvExporting: boolean = false;
  isPaymentPackageLoading: boolean = false;
  isClatTaxPaymentPackageLoading: boolean = false;
  showPaymentPackageModal: boolean = false;
  showClatTaxPaymentPackageModal: boolean = false;
  useCustomPaymentDate: boolean = false;
  useCustomClatTaxPaymentDate: boolean = false;
  areAutentiStatusesRefreshing: boolean = true;
  customPackageName: string = '';
  customPaymentDate: string = '';
  clatTaxPackageModal: any = {
    customPackageName: '',
    customPaymentDate: '',
    taxOffice: null,
    bankAccount: null,
    period: {
      type: 'month',
      value: null,
      year: null,
    }
  };
  bankAccounts: any[] = [];
  isSepa: boolean = false;
  showFlagAccessModal: boolean = false;
  hasLeadForm: boolean = false;
  hasAdvisorForm: boolean = false;
  sharingFlag: any = null;
  isBulkModalLoading: boolean = false;
  showBulkDetailsModal: boolean = false;
  showRecalculateSchedulesModal: boolean = false;

  bulkDetailsData: { toUpdate: { paymentStatus: boolean, paymentBookDate: boolean, description: boolean }, paymentStatus: string, paymentBookDate: string, description: string } = {
    toUpdate: {
      paymentStatus: false,
      paymentBookDate: false,
      description: false,
    },
    paymentStatus: null,
    paymentBookDate: null,
    description: null,
  }

  subscriptionTableData: TableData<any> = {
    items: [
      {
        id: 1,
        code: 'Subscription 1',
        startDate: '2023-07-10T00:00:00+00:00',
        endDate: '2024-07-10T00:00:00+00:00',
        source: 'Leo Hansen',
        agreementDate: '2023-07-13T00:00:00+00:00',
        status: 'active',
        capitalValue: 150000,
        investmentReach: 48912,
        instrumentCount: 48912,
        seriesOfShares: [
          { code: 'B18', instrumentCount: 33912, counterFrom: 1, counterTo: 33912, nominalValue: 1, contributionType: 'cash', contribution: 33912 },
          { code: 'B19', instrumentCount: 15000, counterFrom: 1, counterTo: 15000, nominalValue: 1, contributionType: 'cash', contribution: 15000 },
        ],
        details: [
          {
            code: 'Subscription 1 - B',
            investorName: 'PFR KOFFI FIZ',
            source: 'Leo Hansen',
            instrumentClass: 'Equity',
            instrumentCount: 33912,
            nominalValue: 33912,
            investmentValue: 33912,
            counterFrom: 1,
            counterTo: 33912,
            seriesOfShares: 'B18',
            issueValue: 33912,
          },
          {
            code: 'Subscription 1 - A',
            investorName: 'PFR KOFFI FIZ',
            source: 'Leo Hansen',
            instrumentClass: 'Equity',
            instrumentCount: 15000,
            nominalValue: 15000,
            investmentValue: 15000,
            counterFrom: 1,
            counterTo: 15000,
            seriesOfShares: 'B19',
            issueValue: 15000,
          },
        ]
      },
      {
        id: 2,
        code: 'Subscription 2',
        startDate: '2023-07-23T00:00:00+00:00',
        endDate: '2024-07-23T00:00:00+00:00',
        source: 'Florian Brandt',
        agreementDate: '2023-07-23T00:00:00+00:00',
        status: 'active',
        capitalValue: 3500000,
        investmentReach: 8000,
        instrumentCount: 8000,
        seriesOfShares: [
          { code: 'C03', instrumentCount: 3000, counterFrom: 1, counterTo: 3000, nominalValue: 1, contributionType: 'cash', contribution: 3000 },
          { code: 'C04', instrumentCount: 5000, counterFrom: 1, counterTo: 5000, nominalValue: 1, contributionType: 'cash', contribution: 5000 },
        ],
        details: [
          {
            code: 'Subscription 2 - B',
            investorName: 'John Doe',
            source: 'Florian Brandt',
            instrumentClass: 'Equity',
            instrumentCount: 3000,
            nominalValue: 1,
            investmentValue: 3000,
            counterFrom: 1,
            counterTo: 3000,
            seriesOfShares: 'C03',
            issueValue: 3,
          },
          {
            code: 'Subscription 2 - A',
            investorName: 'John Doe',
            source: 'Florian Brandt',
            instrumentClass: 'Equity',
            instrumentCount: 5000,
            nominalValue: 1,
            investmentValue: 5000,
            counterFrom: 1,
            counterTo: 5000,
            seriesOfShares: 'C04',
            issueValue: 3,
          },
        ]
      },
      {
        id: 3,
        code: 'Subscription 3',
        startDate: '2023-08-02T00:00:00+00:00',
        endDate: '2024-02-02T00:00:00+00:00',
        source: 'Leo Hansen',
        agreementDate: '2024-01-02T00:00:00+00:00',
        status: 'active',
        capitalValue: 320000,
        investmentReach: 10000,
        instrumentCount: 10000,
        seriesOfShares: [
          { code: 'A01', instrumentCount: 5500, counterFrom: 1, counterTo: 5500, nominalValue: 1, contributionType: 'cash', contribution: 5500 },
          { code: 'B01', instrumentCount: 4500, counterFrom: 1, counterTo: 4500, nominalValue: 1, contributionType: 'cash', contribution: 4500 },
        ],
        details: [
          {
            code: 'Subscription 3 - B',
            investorName: 'Florian Brandt',
            source: 'Leo Hansen',
            instrumentClass: 'Equity',
            instrumentCount: 5500,
            nominalValue: 1,
            investmentValue: 5500,
            counterFrom: 1,
            counterTo: 5500,
            seriesOfShares: 'A01',
            issueValue: 3,
          },
          {
            code: 'Subscription 3 - A',
            investorName: 'Florian Brandt',
            source: 'Leo Hansen',
            instrumentClass: 'Equity',
            instrumentCount: 4500,
            nominalValue: 1,
            investmentValue: 4500,
            counterFrom: 1,
            counterTo: 4500,
            seriesOfShares: 'B01',
            issueValue: 3,
          },
        ]
      },
    ],
    perPage: 10,
    query: null,
    totalCount: 10
  }

  async checkAutentiStatuses() {
    try {
      const isRefreshing = await this.$store.dispatch('products/checkAutentiProductStatuses');

      if (isRefreshing) {
        setTimeout(async () => {
          await this.checkAutentiStatuses()
        }, 5000)
      } else {
        this.$nextTick(() => {
          this.applyFiltersEvent(this.filtersQueryString)
          this.areAutentiStatusesRefreshing = isRefreshing
          this.$notify({
            type: 'success',
            title: 'Success',
            text: 'Autenti statuses refreshed successfully'
          })
        })
      }
    } catch (e) {
      this.areAutentiStatusesRefreshing = false
    }
  }

  async refreshAutentiStatuses() {
    if (!this.areAutentiStatusesRefreshing && (this.isMarketplaceAdmin || this.isMarketplaceHead) && this.hasAutenti) {
      this.areAutentiStatusesRefreshing = true
      try {
        await this.$store.dispatch('products/refreshAutentiProductStatuses')
        await this.checkAutentiStatuses()
      } catch (e) {
        const errorMessage = this.$options.filters.errorHandler(e);
        this.$notify({
          type: 'error',
          title: 'An error occurred.',
          text: errorMessage
        });
        this.areAutentiStatusesRefreshing = false
      }
    }
  }

  openBulkDetailsModal() {
    this.showBulkDetailsModal = true;
  }

  closeBulkDetailsModal() {
    this.showBulkDetailsModal = false;
    this.$set(this.bulkDetailsData.toUpdate, 'paymentStatus', false);
    this.$set(this.bulkDetailsData.toUpdate, 'paymentBookDate', false);
    this.$set(this.bulkDetailsData.toUpdate, 'description', false);
    this.$set(this.bulkDetailsData, 'paymentStatus', null);
    this.$set(this.bulkDetailsData, 'paymentBookDate', null);
    this.$set(this.bulkDetailsData, 'description', null);
    (this.$refs.paymentsTable as PaymentsTable).refreshTable();
  }

  openRecalculateSchedulesModal() {
    this.showRecalculateSchedulesModal = true
  }

  closeRecalculateSchedulesModal() {
    this.showRecalculateSchedulesModal = false
  }

  setBulkPaymentStatus(value: any) {
    this.bulkDetailsData.paymentStatus = value;
  }

  setBulkPaymentBookDate(value: any) {
    this.bulkDetailsData.paymentBookDate = value ? value : null;
  }

  setBulkDescription(value: any) {
    this.bulkDetailsData.description = value ? value : null;
  }

  async updateBulkPaymentData() {
    this.isBulkModalLoading = true;

    try {
      const promises: any[] = []

      if (this.bulkDetailsData?.toUpdate?.paymentStatus && this.bulkDetailsData?.toUpdate?.paymentBookDate && this.bulkDetailsData?.toUpdate?.description) {
        const payload = { 
          filtersQuery: this.filtersQueryString,
          data: {
            paymentStatus: this.bulkDetailsData?.paymentStatus,
            paymentBookDate: this.bulkDetailsData?.paymentBookDate,
            paymentMethod: 'bank transfer',
            description: this.bulkDetailsData?.description,
          }
        };
        promises.push(this.$store.dispatch('products/updatePaymentDataByFilters', payload))
      } else {
        if (this.bulkDetailsData?.toUpdate?.paymentStatus) {
          const payload = { filtersQuery: this.filtersQueryString, status: this.bulkDetailsData?.paymentStatus };
          promises.push(this.$store.dispatch('products/updatePaymentStatusesByFilters', payload));
        }
  
        if (this.bulkDetailsData?.toUpdate?.paymentBookDate) {
          const payload = { filtersQuery: this.filtersQueryString, paymentBookDate: this.bulkDetailsData?.paymentBookDate };
          promises.push(this.$store.dispatch('products/updatePaymentBookDatesByFilters', payload));
        }
  
        if (this.bulkDetailsData?.toUpdate?.description) {
          const payload = { filtersQuery: this.filtersQueryString, description: this.bulkDetailsData?.description };
          promises.push(this.$store.dispatch('products/updateDescriptionsByFilters', payload))
        }
      }
      

      await Promise.all(promises)
      this.closeBulkDetailsModal()
      this.$notify({
        duration: 3000,
        type: 'success',
        title: 'Success',
        text: 'Details updated.'
      })

    } catch (err) {
      const errorMessage = this.$options.filters.errorHandler(err);
      this.$notify({
        duration: 3000,
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
    }

    this.isBulkModalLoading = false
  }

  refreshClatTaxPackageModalData() {
    this.useCustomClatTaxPaymentDate = false
    this.clatTaxPackageModal.customPackageName = ''
    this.clatTaxPackageModal.customPaymentDate = ''
    this.clatTaxPackageModal.taxOffice = null
    this.clatTaxPackageModal.period.type = 'month'
    this.clatTaxPackageModal.period.value = null
    this.clatTaxPackageModal.period.year = null

    if (this.bankAccountOptions?.length === 1) {
      this.clatTaxPackageModal.bankAccount = this.bankAccountOptions[0]?.value || null
    } else {
      this.clatTaxPackageModal.bankAccount = null
    }
  }

  get investorsDropdownOptions() {
    const options: any[] = [{label: 'Recalculate schedules', value: 'recalculate-schedules'}]

    if (this.hasAutenti) {
      options.unshift({label: 'Refresh Autenti statuses', value: 'refresh-autenti', disabled: this.areAutentiStatusesRefreshing })
    }

    return options
  }

  get paymentStatuses() {
    return this.paymentDictionaries?.paymentStatus ? this.paymentDictionaries?.paymentStatus?.map((el: any) => {
      return { label: this.$options.filters.capitalizeFirstLetter(el.label), value: el.value }
    }) : []
  }

  get paymentDictionaries() {
    return this.$store.getters['products/getDictionary'] ? this.$store.getters['products/getDictionary'] : {};
  }

  get periodTypes() {
    return periodTypes
  }

  get periodTypeOptions() {
    const options = periodTypeOptions

    return this.clatTaxPackageModal?.period?.type ? options[this.clatTaxPackageModal?.period?.type] : []
  }

  get isPeriodDay() {
    return this.clatTaxPackageModal?.period?.type === 'day'
  }
  
  get isPeriodYear() {
    return this.clatTaxPackageModal?.period?.type === 'year'
  }

  get periodValueLabel() {
    switch (this.clatTaxPackageModal?.period?.type?.toLowerCase()) {
      case "semiannual":
        return 'HALF YEAR'
      default:
        return this.clatTaxPackageModal?.period?.type?.toUpperCase()
    }
  }

  get pageOptions() {
    const pageOptions = [
      {
        label: 'Investors',
        key: 'investors',
        id: 'fe_investors_tab'
      },
      {
        label: 'Products',
        key: 'products',
        id: 'fe_products_tab'
      },
    ];

    if (this.isMarketplaceAdmin && this.isKycEnabled) {
      pageOptions.splice(1, 0, {
        label: 'KYC',
        key: 'kyc',
        id: 'fe_kyc_tab'
      })
    }

    if(this.isMarketplaceAdmin || this.isMarketplaceAccountant) {
      if (!this.hasIssuerId) {
        pageOptions.push({ label: 'Advisors', key: 'advisors', id: 'fe_advisors_tab' });
      }
      pageOptions.push({ label: 'Templates', key: 'templates', id: 'fe_templates_tab' });
      pageOptions.push({ label: 'Campaigns', key: 'campaigns', id: 'fe_campaigns_tab' });
    }

    if(this.isMarketplaceAdmin || this.isMarketplaceHead || this.isMarketplaceAccountant) {
      pageOptions.push({ label: 'Subscriptions', key: 'subscriptions', id: 'fe_subscriptions_tab' });
      pageOptions.push({ label: 'Payments', key: 'payments', id: 'fe_payments_tab' });
      pageOptions.push({ label: 'CLAT tax', key: 'clatTax', id: 'fe_clat_tax_tab' });
    }

    return pageOptions;
  }

  get selectedTabLabel(): string {
    return this.pageOptions?.find((page: any) => page?.key === this.selectedTab)?.label || this.selectedTab
  }

  get hasIssuerId() {
    return !!(this.activeUserData?.issuerManagerId)
  }

  get defaultPaymentPackageName() {
    const date = new Date();
    return `Payment package from ${date.getFullYear()}-${Utils.pad(date.getMonth() + 1, 2)}-${Utils.pad(date.getDate() + 1, 2)} ${Utils.pad(date.getHours(), 2)}:${Utils.pad(date.getMinutes(), 2)}:${Utils.pad(date.getSeconds(), 2)}`;
  }

  get bankAccountOptions() {
    return this.bankAccounts?.filter((el: any) => el.forClatTax && el?.target?.toLowerCase() === 'marketplace')?.map((el: any) => {
      return { label: el?.account, value: el?.account }
    })
  }

  get selectedBankAccount() {
    if (this.clatTaxPackageModal?.bankAccount) {
      return this.bankAccounts?.find((el: any) => el.account === this.clatTaxPackageModal?.bankAccount)
    }
    
    return null
  }

  get taxOfficeCodes() {
    const options = this.$store.getters['investors/taxOfficeCodes']

    return options.map((el: any) => {
      return { label: `${el.value?.toString()} - ${el.label}`, value: el.value }
    })
  }

  closePaymentPackageModal() {
    this.showPaymentPackageModal = false;
    this.$v.$reset();
  }

  closeClatTaxPaymentPackageModal() {
    this.showClatTaxPaymentPackageModal = false;
    this.$v.$reset();
  }

  handleDropdownModalActions(value: any) {
    this.$root.$emit('bv::hide::tooltip')

    switch (value) {
      case 'refresh-autenti':
        this.refreshAutentiStatuses()
        break;
      case 'recalculate-schedules':
        this.openRecalculateSchedulesModal()
        break;
    }
  }

  goToAdvisorForm() {
    if(this.$route.path.includes('company')) {
      this.$router.push('/company/advisors');
    } else {
      this.$router.push('/fund/advisors');
    }
  }

  goToTemplateForm(type: string) {
    if(this.$route.path.includes('company')) {
      this.$router.push({ path: '/company/templates', query: { type } });
    } else {
      this.$router.push({ path: '/fund/templates', query: { type } });
    }
  }

  goToCampaignForm() {
    if (this.$route.path.includes('company')) {
      this.$router.push('/company/fundraising/new-campaign/form/step-one');
    } else {
      this.$router.push('/fund/fundraising/new-campaign/form/step-one');
    }
  }

  enableFlagAccessModal(flag: any) {
    this.sharingFlag = flag;
    this.showFlagAccessModal = true;
  }

  closeFlagAccessModal() {
    this.showFlagAccessModal = false;
    this.sharingFlag = null;
  }

  enableFlagManagementModal() {
    this.showFlagManagementModal = true;
  }

  closeFlagManagementModal() {
    this.showFlagManagementModal = false;
  }

  openPaymentPackageModal() {
    this.showPaymentPackageModal = true;
    this.$v?.$reset()
  }

  async openClatTaxPaymentPackageModal() {
    this.showClatTaxPaymentPackageModal = true
    this.isClatTaxPaymentPackageLoading = true
    this.$v?.$reset()
    
    try {
      if (!this.bankAccounts?.length) {
        const bankAccounts = await this.$store.dispatch('genprox/getContextMarketplaceWalletNumber')
        this.bankAccounts = bankAccounts
      }
    } catch (e) {
      const errorMessage = this.$options.filters.errorHandler(e)
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
    }
    
    this.refreshClatTaxPackageModalData()
    this.isClatTaxPaymentPackageLoading = false
  }

  async exportCsvByFilters() {
    this.isCsvExporting = true;
    try {
      let csvContent = await this.$store.dispatch('products/exportCsvByFilters', this.filtersQueryString);
      csvContent = csvContent.replace(/[\r]+/g, '').replace(';', ' ').replace(/&nbsp;/g, '').replace(/"[^"]*(?:""[^"]*)*"/g, function (m: any) { return m.replace(/\n/g, ''); })
      const universalBOM: string = '\uFEFF';
      let data = `data:text/csv;charset=utf-8,${encodeURIComponent(universalBOM + csvContent)}`;
      const link = document.createElement('a');
      link.setAttribute('href', data)
      link.setAttribute('download', `export.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      const errorMessage = this.$options.filters.errorHandler(error);
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: this.$t(errorMessage).toString()
      });
    }
    this.isCsvExporting = false;
  }

  async generatePaymentPackage() {
    this.$v.$touch();

    if (!this.$v.$error) {
      this.isPaymentPackageLoading = true;
      try {
        const payload: any = {
          name: this.customPackageName ? this.customPackageName : this.defaultPaymentPackageName,
          paymentDate: this.useCustomPaymentDate ? `${this.customPaymentDate} 00:00:00` : null,
          isSepa: this.isSepa,
          items: [],
          filters: this.filtersQueryString,
        }
    
        await this.$store.dispatch('products/generatePaymentPackageByFilters', payload);
        this.closePaymentPackageModal();
        (this.$refs.paymentsTable as PaymentsTable).onFiltersChange(this.filtersQueryString);
        this.$notify({
          type: 'success',
          title: 'Success',
          text: 'Payment package generated.'
        })
      } catch(error) {
        const errorMessage = this.$options.filters.errorHandler(error);
        this.$notify({
          duration: 2500,
          type: 'error',
          title: 'Error',
          text: this.$t(errorMessage).toString()
        });
      }
      this.isPaymentPackageLoading = false;
    }
  }

  buildPeriodPayload() {
    let periodDate = null
    switch (this.clatTaxPackageModal?.period?.type) {
      case 'day':
        periodDate = this.clatTaxPackageModal?.period?.year
        break;
      default:
        periodDate = this.clatTaxPackageModal?.period?.value ? this.clatTaxPackageModal?.period?.value?.replace('XXXX', this.clatTaxPackageModal?.period?.year) : 'XXXX-01-01 00:00:00'?.replace('XXXX', this.clatTaxPackageModal?.period?.year)
        break;
    }

    const payload: any = {
      periodType: this.clatTaxPackageModal?.period?.type?.toLowerCase(),
      periodDate
    }

    return payload
  }

  async generateClatTaxPaymentPackage() {
    this.$v.$touch();

    if (!this.$v.$error) {
      this.isClatTaxPaymentPackageLoading = true;
      try {
        const bankAccount: any = this.bankAccounts?.find((el: any) => el?.account === this.clatTaxPackageModal?.bankAccount)
        const bankAccountType: string = bankAccount ? bankAccount?.type : 'mBank'

        const payload: any = {
          name: this.clatTaxPackageModal?.customPackageName ? this.clatTaxPackageModal.customPackageName : this.defaultPaymentPackageName,
          paymentDate: this.useCustomClatTaxPaymentDate ? `${this.clatTaxPackageModal?.customPaymentDate} 00:00:00` : null,
          bankAccount: this.clatTaxPackageModal?.bankAccount,
          bankAccountType,
          taxOfficeCode: this.clatTaxPackageModal?.taxOffice,
          items: [],
          filters: this.filtersQueryString,
          // ...periodData
        }

        await this.$store.dispatch('products/generateClatTaxPaymentPackageByFilters', payload);
        this.closeClatTaxPaymentPackageModal();
        (this.$refs.clatTaxTable as ClatTaxTable).onFiltersChange(this.filtersQueryString);
        this.$notify({
          type: 'success',
          title: 'Success',
          text: 'Payment package generated.'
        })
      } catch (error) {
        const errorMessage = this.$options.filters.errorHandler(error);
        this.$notify({
          duration: 2500,
          type: 'error',
          title: 'Error',
          text: this.$t(errorMessage).toString()
        });
      }
      this.isClatTaxPaymentPackageLoading = false;
    }
  }

  selectTab(value: string) {
    this.selectedTab = value;

    if(value === 'investors') {
      this.$router.push({ query: null });
    } else {
      if(this.$route.query?.productCode) {
        this.$router.push({ query: { page: value, productCode: this.$route.query.productCode } });
      } else {
        if (this.$route.query?.type) {
          this.$router.push({ query: { page: value, type: this.$route.query?.type } });
        } else {
          this.$router.push({ query: { page: value } });
        }
      }
    }
  }

  applyFiltersEvent(queryString: string) {
    this.filtersQueryString = queryString;
    if(this.selectedTab === 'templates') {
      (this.$refs.agreementsTable as AgreementsTable).onFiltersChange(queryString);
    } else if(this.selectedTab == 'payments') {
      (this.$refs.paymentsTable as PaymentsTable).onFiltersChange(queryString);
    } else if(this.selectedTab == 'clatTax') {
      (this.$refs.clatTaxTable as ClatTaxTable).onFiltersChange(queryString);
    } else if(this.selectedTab === 'campaigns') {
      (this.$refs.campaignsTable as CampaignsTable).onFiltersChange(queryString);
    } else if(this.selectedTab === 'advisors') {
      (this.$refs.advisorsTable as AdvisorsTable).onFiltersChange(queryString);
    } else if(this.selectedTab === 'subscriptions') {
      (this.$refs.subscriptionsTable as SubscriptionsTable).onFiltersChange(queryString);
    } else if (this.selectedTab === 'products') {
      (this.$refs.productTable as ProductTable).onFiltersChange(queryString);
    } else if (this.selectedTab === 'kyc') {
      (this.$refs.kycTable as KycTable).refreshTable()
    } else if (this.selectedTab === 'investors') {
      (this.$refs.investorsTable as InvestorsTable).refreshTable()
    }
  }

  showRecalculateNotification: boolean = false
  recalculateNotificationPeriod: string = ''

  closeRecalculateNotification() {
    Utils.eraseCookie('recalculationProcess')
    this.showRecalculateNotification = false
  }

  checkCookieNotification() {
    let cookie: any = Utils.getCookie('recalculationProcess')

    if (cookie) {
      cookie = JSON.parse(cookie)
      this.showRecalculateNotification = cookie?.contextId === this.activeUserData?.context?.id
      if (cookie?.optionString) {
        this.recalculateNotificationPeriod = cookie?.optionString
      }
    }
  }

  async beforeMount() {
    this.checkCookieNotification()
    try {
      this.$store.dispatch('genprox/getNetworks');
      await this.$store.dispatch('campaigns/getLeadForm');
      this.hasLeadForm = true;
    } catch(e) {
      this.hasLeadForm = false;
      e;
    }

    try {
      await this.$store.dispatch('advisors/getAdvisorForm');
      this.hasAdvisorForm = true;
    } catch(e) {
      this.hasAdvisorForm = false;
      e;
    }
  }

  async mounted() {
    if(this.$route.query?.page) {
      this.selectedTab = this.$route.query.page.toString();
      (this.$refs.tabs as SygniTabs).setOption(this.selectedTab);
    }

    this.$store.dispatch('dictionary/getProductSourceName')
    this.$store.dispatch('products/getDictionaries')
    this.$store.dispatch('investors/getProductTaxOfficeCodes')

    if (this.hasAutenti) this.$store.dispatch('templates/getAutentiDictionaries')

    if ((this.isMarketplaceAdmin || this.isMarketplaceHead) && this.hasAutenti) {
      try {
        const isRefreshing = await this.$store.dispatch('products/checkAutentiProductStatuses');
        if (isRefreshing) {
          this.checkAutentiStatuses()
        } else {
          this.areAutentiStatusesRefreshing = isRefreshing
        }
      } catch (e) {
        this.areAutentiStatusesRefreshing = false
      }
    } else {
      this.areAutentiStatusesRefreshing = false
    }

    await this.$store.dispatch('investors/getInvestors');
    await this.$store.dispatch('products/getProducts');
  }

  get validationObject() {
    if (this.showPaymentPackageModal) {
      return {
        customPaymentDate: { required: requiredIf(() => this.useCustomPaymentDate)}
      }
    }

    if (this.showClatTaxPaymentPackageModal) {
      return {
        clatTaxPackageModal: {
          customPaymentDate: { required: requiredIf(() => this.useCustomClatTaxPaymentDate) },
          taxOffice: { required },
          bankAccount: { required },
          // period: {
          //   type: { required },
          //   value: { required: requiredIf(() => !(this.isPeriodDay || this.isPeriodYear)) },
          //   year: { required },
          // }
        }
      }
    }

    return {}
  }

  validations() {
    return this.validationObject
  }

  @Watch('clatTaxPackageModal.period.type', { immediate: true }) onClatTaxPaymentPackageModalPeriodTypeChange() {
    this.clatTaxPackageModal.period.value = null
    this.$v?.clatTaxPackageModal?.period?.value?.$reset()
  } 

  @Watch('$route') onRouteChange() {
    if (this.$route.query?.page) {
      this.selectedTab = this.$route.query.page.toString();
      (this.$refs.tabs as SygniTabs).setOption(this.selectedTab);
    } else {
      this.selectedTab = 'investors';
      (this.$refs.tabs as SygniTabs).setOption(this.selectedTab);
    }
  }
}
