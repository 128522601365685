var render = function () {
  var _vm$paymentDictionari;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['marketplace-filters filters-container table', _vm.toggleFilters ? 'marketplace-filters--hidden' : '']
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_vm.type === 'subscriptions' ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "table"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('sygni-container-title', [_vm._v("Search")])], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-2"
  }, [_c('sygni-input', {
    attrs: {
      "label": "CODE"
    },
    model: {
      value: _vm.filters.code.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.code, "value", $$v);
      },
      expression: "filters.code.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-input', {
    attrs: {
      "label": "SOURCE"
    },
    model: {
      value: _vm.filters.sourceName.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.sourceName, "value", $$v);
      },
      expression: "filters.sourceName.value"
    }
  })], 1), !_vm.isLlc ? _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-input', {
    attrs: {
      "label": "SERIES OF SHARES",
      "disabled": true
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-date-picker', {
    attrs: {
      "label": 'AGREEMENT DATE',
      "range": true
    },
    model: {
      value: _vm.filters.agreementDate.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.agreementDate, "value", $$v);
      },
      expression: "filters.agreementDate.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-range-input', {
    attrs: {
      "label": "INVESTMENT REACH"
    },
    model: {
      value: _vm.filters.investmentReach.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.investmentReach, "value", $$v);
      },
      expression: "filters.investmentReach.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-range-input', {
    attrs: {
      "label": "CAPITAL VALUE"
    },
    model: {
      value: _vm.filters.capitalValue.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.capitalValue, "value", $$v);
      },
      expression: "filters.capitalValue.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-select', {
    attrs: {
      "label": "REGISTRATION STATUS",
      "options": [{
        label: 'Registered',
        value: 'registered'
      }, {
        label: 'Unregistered',
        value: 'unregistered'
      }]
    },
    model: {
      value: _vm.filters.registrationStatus.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.registrationStatus, "value", $$v);
      },
      expression: "filters.registrationStatus.value"
    }
  })], 1), _c('div', {
    class: ['col-4']
  }, [_c('sygni-buttons-group', {
    staticClass: "investor-status",
    attrs: {
      "label": 'STATUS'
    }
  }, [_vm._l([{
    label: 'New',
    class: 'success',
    value: 'new'
  }, {
    label: 'Authorized',
    class: 'lead',
    value: 'passed'
  }, {
    label: 'Cancelled',
    class: 'danger',
    value: 'cancelled'
  }], function (button) {
    return [_c('sygni-checkable-button', {
      key: button.value,
      class: 'filled ' + button.class,
      attrs: {
        "selectable": true,
        "value": _vm.getArrayValue('status', button.value)
      },
      on: {
        "click": function click($event) {
          return _vm.addRemoveArrayValue('status', button.value);
        }
      }
    }, [_vm._v(" " + _vm._s(button.label) + " ")])];
  })], 2)], 1), _c('div', {
    class: ['col-2 show-results-button', _vm.isLlc ? 'offset-6' : 'offset-4']
  }, [_c('sygni-rounded-button', {
    staticClass: "filled gn-secondary",
    attrs: {
      "loading": _vm.isLoading,
      "selectable": false
    },
    on: {
      "click": function click($event) {
        return _vm.applyFilters();
      }
    }
  }, [_vm._v(" Show results (" + _vm._s(_vm.itemsCount) + ")")]), _c('div', {
    staticClass: "clear-all-button",
    on: {
      "click": function click($event) {
        return _vm.clearAll();
      }
    }
  }, [_vm._v("Clear all")])], 1)])])])]) : _vm._e(), _vm.type === 'templates' ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "table"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('sygni-container-title', [_vm._v("Search")])], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-2"
  }, [_c('sygni-input', {
    attrs: {
      "label": 'TITLE'
    },
    model: {
      value: _vm.filters.title.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.title, "value", $$v);
      },
      expression: "filters.title.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-input', {
    attrs: {
      "label": 'CREATOR'
    },
    model: {
      value: _vm.filters.authorName.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.authorName, "value", $$v);
      },
      expression: "filters.authorName.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-date-picker', {
    attrs: {
      "label": 'CREATED DATE',
      "range": true
    },
    model: {
      value: _vm.filters.createdAt.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.createdAt, "value", $$v);
      },
      expression: "filters.createdAt.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-date-picker', {
    attrs: {
      "label": 'EDITED DATE',
      "range": true
    },
    model: {
      value: _vm.filters.updatedAt.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.updatedAt, "value", $$v);
      },
      expression: "filters.updatedAt.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-select', {
    attrs: {
      "options": _vm.typeOptions,
      "label": "TYPE"
    },
    model: {
      value: _vm.filters.isAttachment.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.isAttachment, "value", $$v);
      },
      expression: "filters.isAttachment.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-select', {
    attrs: {
      "options": _vm.autentiOptions,
      "disabled": !_vm.hasAutenti,
      "label": "AUTENTI"
    },
    model: {
      value: _vm.filters.autentiEnabled.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.autentiEnabled, "value", $$v);
      },
      expression: "filters.autentiEnabled.value"
    }
  })], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-4"
  }, [_c('sygni-buttons-group', {
    staticClass: "investor-status",
    attrs: {
      "label": 'STATUS'
    }
  }, [_vm._l([{
    label: 'DRAFT',
    class: 'success',
    value: 'DRAFT'
  }, {
    label: 'ACTIVE',
    class: 'primary',
    value: 'ACTIVE'
  }, {
    label: 'ARCHIVED',
    class: 'lead',
    value: 'ARCHIVED'
  }], function (button) {
    return [_c('sygni-checkable-button', {
      key: button.value,
      class: 'filled ' + button.class,
      attrs: {
        "selectable": true,
        "value": _vm.getArrayValue('status', button.value)
      },
      on: {
        "click": function click($event) {
          return _vm.addRemoveArrayValue('status', button.value);
        }
      }
    }, [_vm._v(" " + _vm._s(button.label) + " ")])];
  })], 2)], 1), _c('div', {
    staticClass: "col-6"
  }), _c('div', {
    staticClass: "col-2 show-results-button"
  }, [_c('sygni-rounded-button', {
    staticClass: "filled gn-secondary",
    attrs: {
      "loading": _vm.isLoading,
      "selectable": false
    },
    on: {
      "click": function click($event) {
        return _vm.applyFilters();
      }
    }
  }, [_vm._v(" Show results (" + _vm._s(_vm.itemsCount) + ")")]), _c('div', {
    staticClass: "clear-all-button",
    on: {
      "click": function click($event) {
        return _vm.clearAll();
      }
    }
  }, [_vm._v("Clear all")])], 1)])])])]) : _vm._e(), _vm.type === 'advisors' && !_vm.hasIssuerId ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "table"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('sygni-container-title', [_vm._v("Search")])], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-2"
  }, [_c('sygni-input', {
    attrs: {
      "label": 'FIRST NAME'
    },
    model: {
      value: _vm.filters.firstName.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.firstName, "value", $$v);
      },
      expression: "filters.firstName.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-input', {
    attrs: {
      "label": 'SURNAME'
    },
    model: {
      value: _vm.filters.surname.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.surname, "value", $$v);
      },
      expression: "filters.surname.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-input', {
    attrs: {
      "label": 'EMAIL'
    },
    model: {
      value: _vm.filters.email.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.email, "value", $$v);
      },
      expression: "filters.email.value"
    }
  })], 1), _c('div', {
    staticClass: "col-3"
  }, [_c('sygni-date-picker', {
    staticClass: "border-collapse",
    attrs: {
      "range": true,
      "label": 'START POWER OF ATTORNEY DATE'
    },
    model: {
      value: _vm.filters.startPowerOfAttorney.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.startPowerOfAttorney, "value", $$v);
      },
      expression: "filters.startPowerOfAttorney.value"
    }
  })], 1), _c('div', {
    staticClass: "col-3"
  }, [_c('sygni-date-picker', {
    staticClass: "border-collapse",
    attrs: {
      "range": true,
      "label": 'END POWER OF ATTORNEY DATE'
    },
    model: {
      value: _vm.filters.endPowerOfAttorney.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.endPowerOfAttorney, "value", $$v);
      },
      expression: "filters.endPowerOfAttorney.value"
    }
  })], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-2"
  }, [_c('sygni-input', {
    attrs: {
      "label": 'IDENTIFICATION NUMBER'
    },
    model: {
      value: _vm.filters.identificationNumber.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.identificationNumber, "value", $$v);
      },
      expression: "filters.identificationNumber.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-date-picker', {
    staticClass: "border-collaps",
    attrs: {
      "range": true,
      "label": "LAST LOGIN"
    },
    model: {
      value: _vm.filters.lastLogin.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.lastLogin, "value", $$v);
      },
      expression: "filters.lastLogin.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-select', {
    attrs: {
      "label": 'NETWORKS',
      "options": _vm.networkOptions
    },
    on: {
      "input": _vm.updateNetworksSingular
    },
    model: {
      value: _vm.selectedNetworkOptions,
      callback: function callback($$v) {
        _vm.selectedNetworkOptions = $$v;
      },
      expression: "selectedNetworkOptions"
    }
  })], 1), _c('div', {
    staticClass: "col-4"
  }, [_c('sygni-buttons-group', {
    staticClass: "investor-status",
    attrs: {
      "label": 'STATUS'
    }
  }, [_vm._l([{
    label: 'Initial',
    class: 'success',
    value: 'initial'
  }, {
    label: 'Active',
    class: 'primary',
    value: 'active'
  }, {
    label: 'Deleted',
    class: 'danger',
    value: 'deleted'
  }], function (button) {
    return [_c('sygni-checkable-button', {
      key: button.value,
      class: 'filled ' + button.class,
      attrs: {
        "selectable": true,
        "value": _vm.getArrayValue('status', button.value)
      },
      on: {
        "click": function click($event) {
          return _vm.addRemoveArrayValue('status', button.value);
        }
      }
    }, [_vm._v(" " + _vm._s(button.label) + " ")])];
  })], 2)], 1), _c('div', {
    staticClass: "col-2 show-results-button"
  }, [_c('sygni-rounded-button', {
    staticClass: "filled gn-secondary",
    attrs: {
      "loading": _vm.isLoading,
      "selectable": false
    },
    on: {
      "click": function click($event) {
        return _vm.applyFilters();
      }
    }
  }, [_vm._v(" Show results (" + _vm._s(_vm.itemsCount) + ")")]), _c('div', {
    staticClass: "clear-all-button",
    on: {
      "click": function click($event) {
        return _vm.clearAll();
      }
    }
  }, [_vm._v("Clear all")])], 1)])])])]) : _vm._e(), _vm.type === 'campaigns' ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "table"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('sygni-container-title', [_vm._v("Search")])], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-4"
  }, [_c('sygni-input', {
    attrs: {
      "label": 'CAMPAIGN NAME'
    },
    model: {
      value: _vm.filters.campaignName.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.campaignName, "value", $$v);
      },
      expression: "filters.campaignName.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-select', {
    attrs: {
      "options": [],
      "label": 'CATEGORY',
      "disabled": true
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-select', {
    attrs: {
      "options": [],
      "label": "TYPE",
      "disabled": true
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-select', {
    attrs: {
      "options": [],
      "label": 'INTEREST P.A.',
      "disabled": true
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-range-input', {
    attrs: {
      "displayFormat": 'currency',
      "label": 'INVESTMENT  VALUE'
    },
    model: {
      value: _vm.filters.investmentValue.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.investmentValue, "value", $$v);
      },
      expression: "filters.investmentValue.value"
    }
  })], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-2"
  }, [_c('sygni-date-picker', {
    staticClass: "border-collapse",
    attrs: {
      "label": 'START DATE',
      "range": true
    },
    model: {
      value: _vm.filters.startDate.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.startDate, "value", $$v);
      },
      expression: "filters.startDate.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-date-picker', {
    staticClass: "border-collapse",
    attrs: {
      "label": 'END DATE',
      "range": true
    },
    model: {
      value: _vm.filters.endDate.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.endDate, "value", $$v);
      },
      expression: "filters.endDate.value"
    }
  })], 1), _c('div', {
    staticClass: "col-4"
  }, [_c('sygni-buttons-group', {
    staticClass: "investor-status",
    attrs: {
      "label": 'CAMPAIGN STATUS'
    }
  }, [_vm._l([{
    label: 'Draft',
    class: 'success',
    value: 'draft'
  }, {
    label: 'Active',
    class: 'primary',
    value: 'active'
  }, {
    label: 'Finished',
    class: 'disabled',
    value: 'finished'
  }, {
    label: 'Archived',
    class: 'danger',
    value: 'archived'
  }], function (button) {
    return [_c('sygni-checkable-button', {
      key: button.value,
      class: 'outline ' + button.class,
      attrs: {
        "selectable": true,
        "value": _vm.getArrayValue('status', button.value)
      },
      on: {
        "click": function click($event) {
          return _vm.addRemoveArrayValue('status', button.value);
        }
      }
    }, [_vm._v(" " + _vm._s(button.label) + " ")])];
  })], 2)], 1), _c('div', {
    staticClass: "col-2"
  }), _c('div', {
    staticClass: "col-2 show-results-button"
  }, [_c('sygni-rounded-button', {
    staticClass: "filled gn-secondary",
    attrs: {
      "loading": _vm.isLoading,
      "selectable": false
    },
    on: {
      "click": function click($event) {
        return _vm.applyFilters();
      }
    }
  }, [_vm._v(" Show results (" + _vm._s(_vm.itemsCount) + ")")]), _c('div', {
    staticClass: "clear-all-button",
    on: {
      "click": function click($event) {
        return _vm.clearAll();
      }
    }
  }, [_vm._v("Clear all")])], 1)])])])]) : _vm._e(), _vm.type === 'kyc' ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "table"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('sygni-container-title', [_vm._v("Search")])], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-3"
  }, [_c('sygni-input', {
    attrs: {
      "label": 'INVESTOR EMAIL'
    },
    model: {
      value: _vm.filters.investorEmail.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.investorEmail, "value", $$v);
      },
      expression: "filters.investorEmail.value"
    }
  })], 1), _c('div', {
    staticClass: "col-3"
  }, [_c('sygni-input', {
    attrs: {
      "label": 'BROKER NAME'
    },
    model: {
      value: _vm.filters.brokerName.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.brokerName, "value", $$v);
      },
      expression: "filters.brokerName.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-date-picker', {
    attrs: {
      "label": 'CREATED DATE',
      "range": true
    },
    model: {
      value: _vm.filters.createdAt.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.createdAt, "value", $$v);
      },
      expression: "filters.createdAt.value"
    }
  })], 1), _c('div', {
    staticClass: "col-4"
  }, [_c('sygni-buttons-group', {
    staticClass: "investor-status",
    attrs: {
      "label": 'STATUS'
    }
  }, [_vm._l([{
    label: 'Sent',
    class: 'lead',
    value: 'sent'
  }, {
    label: 'Viewed',
    class: 'success',
    value: 'viewed'
  }, {
    label: 'Submitted',
    class: 'primary',
    value: 'submitted'
  }], function (button) {
    return [_c('sygni-checkable-button', {
      key: button.value,
      class: 'outline ' + button.class,
      attrs: {
        "selectable": true,
        "value": _vm.getArrayValue('status', button.value)
      },
      on: {
        "click": function click($event) {
          return _vm.addRemoveArrayValue('status', button.value);
        }
      }
    }, [_vm._v(" " + _vm._s(button.label) + " ")])];
  })], 2)], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-2 offset-10 show-results-button"
  }, [_c('sygni-rounded-button', {
    staticClass: "filled gn-secondary",
    attrs: {
      "loading": _vm.isLoading,
      "selectable": false
    },
    on: {
      "click": function click($event) {
        return _vm.applyFilters();
      }
    }
  }, [_vm._v(" Show results (" + _vm._s(_vm.itemsCount) + ")")]), _c('div', {
    staticClass: "clear-all-button",
    on: {
      "click": function click($event) {
        return _vm.clearAll();
      }
    }
  }, [_vm._v("Clear all")])], 1)])])])]) : _vm._e(), _vm.type === 'payments' ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "table"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('sygni-container-title', [_vm._v("Search")])], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-2"
  }, [_c('sygni-input', {
    attrs: {
      "label": "CAMPAIGN NAME"
    },
    model: {
      value: _vm.filters.campaignName.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.campaignName, "value", $$v);
      },
      expression: "filters.campaignName.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-input', {
    attrs: {
      "label": "PRODUCT CODE"
    },
    model: {
      value: _vm.filters.productCode.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.productCode, "value", $$v);
      },
      expression: "filters.productCode.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-input', {
    attrs: {
      "label": "INVESTOR NAME"
    },
    model: {
      value: _vm.filters.investmentClient.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.investmentClient, "value", $$v);
      },
      expression: "filters.investmentClient.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-select', {
    attrs: {
      "label": "PRODUCT TYPE",
      "options": _vm.productSelects.productType
    },
    model: {
      value: _vm.filters.productType.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.productType, "value", $$v);
      },
      expression: "filters.productType.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-date-picker', {
    attrs: {
      "range": true,
      "label": "AGREEMENT DATE"
    },
    model: {
      value: _vm.filters.agreementDate.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.agreementDate, "value", $$v);
      },
      expression: "filters.agreementDate.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-date-picker', {
    attrs: {
      "range": true,
      "label": "OUTFLOW DATE"
    },
    model: {
      value: _vm.filters.repaymentDate.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.repaymentDate, "value", $$v);
      },
      expression: "filters.repaymentDate.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-select', {
    attrs: {
      "label": "PAYMENT TYPE",
      "options": (_vm$paymentDictionari = _vm.paymentDictionaries) !== null && _vm$paymentDictionari !== void 0 && _vm$paymentDictionari.paymentType ? _vm.paymentDictionaries.paymentType : []
    },
    model: {
      value: _vm.filters.paymentType.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.paymentType, "value", $$v);
      },
      expression: "filters.paymentType.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-date-picker', {
    attrs: {
      "range": true,
      "label": "DATE FROM"
    },
    model: {
      value: _vm.filters.interestPeriodFrom.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.interestPeriodFrom, "value", $$v);
      },
      expression: "filters.interestPeriodFrom.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-date-picker', {
    attrs: {
      "range": true,
      "label": "DATE TO"
    },
    model: {
      value: _vm.filters.interestPeriodTo.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.interestPeriodTo, "value", $$v);
      },
      expression: "filters.interestPeriodTo.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-range-input', {
    attrs: {
      "label": "TAX VALUE",
      "displayFormat": "currency"
    },
    model: {
      value: _vm.filters.interestTax.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.interestTax, "value", $$v);
      },
      expression: "filters.interestTax.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-range-input', {
    attrs: {
      "label": "NET VALUE",
      "displayFormat": "currency"
    },
    model: {
      value: _vm.filters.interestAmountNet.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.interestAmountNet, "value", $$v);
      },
      expression: "filters.interestAmountNet.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-range-input', {
    attrs: {
      "label": "GROSS VALUE",
      "displayFormat": "currency"
    },
    model: {
      value: _vm.filters.interestAmountGross.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.interestAmountGross, "value", $$v);
      },
      expression: "filters.interestAmountGross.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-multi-select', {
    attrs: {
      "clear-on-select": false,
      "close-on-select": false,
      "display-values": true,
      "label": 'CURRENCY',
      "multiple": true,
      "options": _vm.usedCurrencies,
      "preserve-search": false,
      "searchable": true,
      "select-all": true
    },
    on: {
      "input": _vm.updateCurrencies,
      "toggleAll": _vm.toggleAllCurrencies
    },
    model: {
      value: _vm.selectedProductCurrencies,
      callback: function callback($$v) {
        _vm.selectedProductCurrencies = $$v;
      },
      expression: "selectedProductCurrencies"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-input', {
    attrs: {
      "label": "BANK ACCOUNT"
    },
    model: {
      value: _vm.filters.bankAccount.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.bankAccount, "value", $$v);
      },
      expression: "filters.bankAccount.value"
    }
  })], 1), _c('div', {
    staticClass: "col-4"
  }, [_c('sygni-buttons-group', {
    staticClass: "investor-status",
    attrs: {
      "label": 'STATUS'
    }
  }, [_vm._l([{
    label: 'Paid',
    class: 'primary',
    value: 'paid'
  }, {
    label: 'Unpaid',
    class: 'danger',
    value: 'unpaid'
  }, {
    label: 'Scheduled',
    class: 'success',
    value: 'scheduled'
  }, {
    label: 'Cancelled',
    class: 'danger',
    value: 'cancelled'
  }, {
    label: 'Partially Paid',
    class: 'lead',
    value: 'partially paid'
  }], function (button) {
    return [_c('sygni-checkable-button', {
      key: button.value,
      class: 'filled ' + button.class,
      attrs: {
        "selectable": true,
        "value": _vm.getArrayValue('paymentStatus', button.value)
      },
      on: {
        "click": function click($event) {
          return _vm.addRemoveArrayValue('paymentStatus', button.value);
        }
      }
    }, [_vm._v(" " + _vm._s(button.label) + " ")])];
  })], 2)], 1), _c('div', {
    staticClass: "offset-2 col-2 show-results-button"
  }, [_c('sygni-rounded-button', {
    staticClass: "filled gn-secondary",
    attrs: {
      "loading": _vm.isLoading,
      "selectable": false
    },
    on: {
      "click": function click($event) {
        return _vm.applyFilters();
      }
    }
  }, [_vm._v(" Show results (" + _vm._s(_vm.itemsCount) + ")")]), _c('div', {
    staticClass: "clear-all-button",
    on: {
      "click": function click($event) {
        return _vm.clearAll();
      }
    }
  }, [_vm._v("Clear all")])], 1)])])])]) : _vm._e(), _vm.type === 'clatTax' ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "table"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('sygni-container-title', [_vm._v("Search")])], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-2"
  }, [_c('sygni-input', {
    attrs: {
      "label": "PRODUCT CODE"
    },
    model: {
      value: _vm.filters.productCode.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.productCode, "value", $$v);
      },
      expression: "filters.productCode.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-input', {
    attrs: {
      "label": "INVESTOR NAME"
    },
    model: {
      value: _vm.filters.investor.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.investor, "value", $$v);
      },
      expression: "filters.investor.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-select', {
    attrs: {
      "label": "PRODUCT TYPE",
      "options": _vm.productSelects.productType
    },
    model: {
      value: _vm.filters.productType.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.productType, "value", $$v);
      },
      expression: "filters.productType.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-date-picker', {
    attrs: {
      "range": true,
      "label": "PAYMENT DEADLINE"
    },
    model: {
      value: _vm.filters.paymentDeadline.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.paymentDeadline, "value", $$v);
      },
      expression: "filters.paymentDeadline.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-range-input', {
    attrs: {
      "label": "VALUE"
    },
    model: {
      value: _vm.filters.value.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.value, "value", $$v);
      },
      expression: "filters.value.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-date-picker', {
    attrs: {
      "range": true,
      "label": "PAYMENT DATE"
    },
    model: {
      value: _vm.filters.paymentDate.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.paymentDate, "value", $$v);
      },
      expression: "filters.paymentDate.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-select', {
    attrs: {
      "label": "STATEMENT GENERATED",
      "options": [{
        label: 'YES',
        value: '1'
      }, {
        label: 'NO',
        value: '0'
      }]
    },
    model: {
      value: _vm.filters.statementGenerated.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.statementGenerated, "value", $$v);
      },
      expression: "filters.statementGenerated.value"
    }
  })], 1), _c('div', {
    staticClass: "col-6"
  }, [_c('sygni-buttons-group', {
    staticClass: "investor-status",
    attrs: {
      "label": 'STATUS'
    }
  }, [_vm._l([{
    label: 'Paid',
    class: 'primary',
    value: 'paid'
  }, {
    label: 'Unpaid',
    class: 'danger',
    value: 'unpaid'
  }, {
    label: 'Scheduled',
    class: 'success',
    value: 'scheduled'
  }, {
    label: 'Cancelled',
    class: 'danger',
    value: 'cancelled'
  }, {
    label: 'Partially Paid',
    class: 'lead',
    value: 'partially paid'
  }], function (button) {
    return [_c('sygni-checkable-button', {
      key: button.value,
      class: 'filled ' + button.class,
      attrs: {
        "selectable": true,
        "value": _vm.getArrayValue('paymentStatus', button.value)
      },
      on: {
        "click": function click($event) {
          return _vm.addRemoveArrayValue('paymentStatus', button.value);
        }
      }
    }, [_vm._v(" " + _vm._s(button.label) + " ")])];
  })], 2)], 1), _c('div', {
    staticClass: "col-2"
  }), _c('div', {
    staticClass: "col-2 show-results-button"
  }, [_c('sygni-rounded-button', {
    staticClass: "filled gn-secondary",
    attrs: {
      "loading": _vm.isLoading,
      "selectable": false
    },
    on: {
      "click": function click($event) {
        return _vm.applyFilters();
      }
    }
  }, [_vm._v(" Show results (" + _vm._s(_vm.itemsCount) + ")")]), _c('div', {
    staticClass: "clear-all-button",
    on: {
      "click": function click($event) {
        return _vm.clearAll();
      }
    }
  }, [_vm._v("Clear all")])], 1)])])])]) : _vm._e(), _vm.type === 'products' ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('sygni-container-title', [_vm._v(" Search ")])], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-2"
  }, [_c('sygni-input', {
    attrs: {
      "testId": "fe_search_investor_name",
      "label": 'INVESTOR NAME'
    },
    model: {
      value: _vm.filters.investmentClientName.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.investmentClientName, "value", $$v);
      },
      expression: "filters.investmentClientName.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-select', {
    attrs: {
      "options": _vm.investorSelects.sources,
      "label": 'INVESTOR SOURCE'
    },
    model: {
      value: _vm.filters.investmentClientSource.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.investmentClientSource, "value", $$v);
      },
      expression: "filters.investmentClientSource.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-select', {
    attrs: {
      "options": _vm.productSelects.class,
      "label": 'PRODUCT CLASS'
    },
    model: {
      value: _vm.filters.productClass.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.productClass, "value", $$v);
      },
      expression: "filters.productClass.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-input', {
    attrs: {
      "label": 'PRODUCT CODE'
    },
    model: {
      value: _vm.filters.productCode.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.productCode, "value", $$v);
      },
      expression: "filters.productCode.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-select', {
    attrs: {
      "options": _vm.productSelects.source,
      "searchable": true,
      "label": 'PRODUCT SOURCE'
    },
    model: {
      value: _vm.filters.productSource.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.productSource, "value", $$v);
      },
      expression: "filters.productSource.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-date-picker', {
    attrs: {
      "label": 'AGREEMENT DATE',
      "range": true
    },
    model: {
      value: _vm.filters.productAgreementDate.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.productAgreementDate, "value", $$v);
      },
      expression: "filters.productAgreementDate.value"
    }
  })], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-2"
  }, [_c('sygni-select', {
    attrs: {
      "options": _vm.investorSelects.investmentPotentials,
      "label": 'INVESTOR POTENTIAL'
    },
    model: {
      value: _vm.filters.investmentClientInvestmentPotential.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.investmentClientInvestmentPotential, "value", $$v);
      },
      expression: "filters.investmentClientInvestmentPotential.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-multi-select', {
    attrs: {
      "clear-on-select": false,
      "close-on-select": false,
      "display-values": true,
      "label": 'INVESTOR STATUS',
      "multiple": true,
      "options": _vm.investorStatuses,
      "preserve-search": false,
      "searchable": true,
      "select-all": true
    },
    on: {
      "input": _vm.updateInvestorStatuses,
      "toggleAll": _vm.toggleAllInvestorStatuses
    },
    model: {
      value: _vm.selectedInvestorStatuses,
      callback: function callback($$v) {
        _vm.selectedInvestorStatuses = $$v;
      },
      expression: "selectedInvestorStatuses"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-date-picker', {
    attrs: {
      "label": "INFLOW DATE",
      "disabled": false,
      "range": true
    },
    model: {
      value: _vm.filters.paymentDate.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.paymentDate, "value", $$v);
      },
      expression: "filters.paymentDate.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-date-picker', {
    attrs: {
      "label": "OUTFLOW DATE",
      "disabled": false,
      "range": true
    },
    model: {
      value: _vm.filters.interestPeriodTo.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.interestPeriodTo, "value", $$v);
      },
      expression: "filters.interestPeriodTo.value"
    }
  })], 1), _c('div', {
    staticClass: "col-4"
  }, [_c('sygni-range-input', {
    attrs: {
      "displayFormat": 'currency',
      "label": 'INSTRUMENT VALUE'
    },
    model: {
      value: _vm.filters.productValue.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.productValue, "value", $$v);
      },
      expression: "filters.productValue.value"
    }
  })], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-2"
  }, [_c('sygni-multi-select', {
    attrs: {
      "clear-on-select": false,
      "close-on-select": false,
      "display-values": true,
      "label": 'CURRENCY',
      "multiple": true,
      "options": _vm.usedCurrencies,
      "preserve-search": false,
      "searchable": true,
      "select-all": true
    },
    on: {
      "input": _vm.updateCurrencies,
      "toggleAll": _vm.toggleAllCurrencies
    },
    model: {
      value: _vm.selectedProductCurrencies,
      callback: function callback($$v) {
        _vm.selectedProductCurrencies = $$v;
      },
      expression: "selectedProductCurrencies"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-multi-select', {
    attrs: {
      "clear-on-select": false,
      "close-on-select": false,
      "display-values": true,
      "label": 'PRODUCT STATUS',
      "multiple": true,
      "options": _vm.productStatuses,
      "preserve-search": false,
      "searchable": true,
      "select-all": true
    },
    on: {
      "input": _vm.updateProductStatuses,
      "toggleAll": _vm.toggleAllProductStatuses
    },
    model: {
      value: _vm.selectedProductStatuses,
      callback: function callback($$v) {
        _vm.selectedProductStatuses = $$v;
      },
      expression: "selectedProductStatuses"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-select', {
    attrs: {
      "label": "SCHEDULE STATUS",
      "options": [{
        label: 'Generated',
        value: '1'
      }, {
        label: 'Not generated',
        value: '0'
      }]
    },
    model: {
      value: _vm.filters.repaymentScheduleGenerated.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.repaymentScheduleGenerated, "value", $$v);
      },
      expression: "filters.repaymentScheduleGenerated.value"
    }
  })], 1), _vm.hasAutenti ? [_c('div', {
    staticClass: "col-2"
  }, [_c('sygni-multi-select', {
    attrs: {
      "clear-on-select": false,
      "close-on-select": false,
      "display-values": true,
      "label": 'E-SIGNATURE STATUS',
      "multiple": true,
      "options": _vm.autentiStatuses,
      "preserve-search": false,
      "searchable": true,
      "select-all": true
    },
    on: {
      "input": _vm.updateAutentiStatuses,
      "toggleAll": _vm.toggleAllAutentiStatuses
    },
    model: {
      value: _vm.selectedAutentiStatuses,
      callback: function callback($$v) {
        _vm.selectedAutentiStatuses = $$v;
      },
      expression: "selectedAutentiStatuses"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-multi-select', {
    attrs: {
      "clear-on-select": false,
      "close-on-select": false,
      "display-values": true,
      "label": 'SIGNED STATUS',
      "multiple": true,
      "options": _vm.signedStatuses,
      "preserve-search": false,
      "searchable": true,
      "select-all": true
    },
    on: {
      "input": _vm.updateSignedStatuses,
      "toggleAll": _vm.toggleAllSignedStatuses
    },
    model: {
      value: _vm.selectedSignedStatuses,
      callback: function callback($$v) {
        _vm.selectedSignedStatuses = $$v;
      },
      expression: "selectedSignedStatuses"
    }
  })], 1)] : _c('div', {
    staticClass: "col-4"
  }), _c('div', {
    staticClass: "col-2 show-results-button"
  }, [_c('sygni-rounded-button', {
    staticClass: "filled gn-secondary",
    attrs: {
      "id": "fe_search",
      "loading": _vm.isLoading,
      "selectable": false
    },
    on: {
      "click": function click($event) {
        return _vm.applyFilters();
      }
    }
  }, [_vm._v(" Show results (" + _vm._s(_vm.itemsCount) + ")")]), _c('div', {
    staticClass: "clear-all-button",
    on: {
      "click": function click($event) {
        return _vm.clearAll();
      }
    }
  }, [_vm._v("Clear all")])], 1)], 2)])]) : _vm._e(), _vm.type !== 'templates' && _vm.type !== 'campaigns' && _vm.type !== 'advisors' && _vm.type !== 'payments' && _vm.type !== 'clatTax' && _vm.type !== 'subscriptions' && _vm.type !== 'products' && _vm.type !== 'kyc' ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-xl-4 col-5"
  }, [_c('div', {
    staticClass: "table"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('sygni-container-title', [_vm._v(" Search by investor ")])], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-6"
  }, [_c('sygni-input', {
    attrs: {
      "testId": "fe_search_investor_name",
      "label": 'INVESTOR NAME'
    },
    model: {
      value: _vm.filters.investmentClientName.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.investmentClientName, "value", $$v);
      },
      expression: "filters.investmentClientName.value"
    }
  })], 1), _c('div', {
    staticClass: "col-6"
  }, [_c('sygni-select', {
    attrs: {
      "options": _vm.investorSelects.sources,
      "label": 'INVESTOR SOURCE'
    },
    model: {
      value: _vm.filters.investmentClientSource.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.investmentClientSource, "value", $$v);
      },
      expression: "filters.investmentClientSource.value"
    }
  })], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-6"
  }, [_c('sygni-select', {
    attrs: {
      "options": _vm.investorSelects.investmentPotentials,
      "label": 'INVESTOR POTENTIAL'
    },
    model: {
      value: _vm.filters.investmentClientInvestmentPotential.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.investmentClientInvestmentPotential, "value", $$v);
      },
      expression: "filters.investmentClientInvestmentPotential.value"
    }
  })], 1), _c('div', {
    staticClass: "col-6"
  }, [_c('sygni-multi-select', {
    attrs: {
      "clear-on-select": false,
      "close-on-select": false,
      "display-values": true,
      "label": 'INVESTOR STATUS',
      "multiple": true,
      "options": _vm.investorStatuses,
      "preserve-search": false,
      "searchable": true,
      "select-all": true
    },
    on: {
      "input": _vm.updateInvestorStatuses,
      "toggleAll": _vm.toggleAllInvestorStatuses
    },
    model: {
      value: _vm.selectedInvestorStatuses,
      callback: function callback($$v) {
        _vm.selectedInvestorStatuses = $$v;
      },
      expression: "selectedInvestorStatuses"
    }
  })], 1)]), _vm._m(0)])]), _c('div', {
    staticClass: "col-xl-1 col-auto p-0"
  }), _c('div', {
    staticClass: "col-7"
  }, [_c('div', {
    staticClass: "table"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('sygni-container-title', [_vm._v(" Search by product ")])], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-3"
  }, [_c('sygni-select', {
    attrs: {
      "options": _vm.productSelects.class,
      "label": 'PRODUCT CLASS'
    },
    model: {
      value: _vm.filters.productClass.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.productClass, "value", $$v);
      },
      expression: "filters.productClass.value"
    }
  })], 1), _c('div', {
    staticClass: "col-3"
  }, [_c('sygni-input', {
    attrs: {
      "label": 'PRODUCT CODE'
    },
    model: {
      value: _vm.filters.productCode.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.productCode, "value", $$v);
      },
      expression: "filters.productCode.value"
    }
  })], 1), _c('div', {
    staticClass: "col-3"
  }, [_c('sygni-select', {
    attrs: {
      "options": _vm.productSelects.source,
      "searchable": true,
      "label": 'PRODUCT SOURCE'
    },
    model: {
      value: _vm.filters.productSource.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.productSource, "value", $$v);
      },
      expression: "filters.productSource.value"
    }
  })], 1), _c('div', {
    staticClass: "col-3"
  }, [_c('sygni-date-picker', {
    attrs: {
      "label": 'AGREEMENT DATE',
      "range": true
    },
    model: {
      value: _vm.filters.productAgreementDate.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.productAgreementDate, "value", $$v);
      },
      expression: "filters.productAgreementDate.value"
    }
  })], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-3"
  }, [_vm.type === 'products' ? _c('sygni-date-picker', {
    attrs: {
      "label": "INFLOW DATE",
      "disabled": false,
      "range": true
    },
    model: {
      value: _vm.filters.paymentDate.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.paymentDate, "value", $$v);
      },
      expression: "filters.paymentDate.value"
    }
  }) : _vm._e(), _vm.type === 'investors' ? _c('sygni-date-picker', {
    attrs: {
      "label": "INFLOW DATE",
      "disabled": false,
      "range": true
    },
    model: {
      value: _vm.filters.productPaymentDate.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.productPaymentDate, "value", $$v);
      },
      expression: "filters.productPaymentDate.value"
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "col-3"
  }, [_vm.type === 'products' ? _c('sygni-date-picker', {
    attrs: {
      "label": "OUTFLOW DATE",
      "disabled": false,
      "range": true
    },
    model: {
      value: _vm.filters.interestPeriodTo.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.interestPeriodTo, "value", $$v);
      },
      expression: "filters.interestPeriodTo.value"
    }
  }) : _vm._e(), _vm.type === 'investors' ? _c('sygni-date-picker', {
    attrs: {
      "label": "OUTFLOW DATE",
      "disabled": false,
      "range": true
    },
    model: {
      value: _vm.filters.productInterestPeriodTo.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.productInterestPeriodTo, "value", $$v);
      },
      expression: "filters.productInterestPeriodTo.value"
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "col-6"
  }, [_c('sygni-range-input', {
    attrs: {
      "displayFormat": 'currency',
      "label": 'INSTRUMENT VALUE'
    },
    model: {
      value: _vm.filters.productValue.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.productValue, "value", $$v);
      },
      expression: "filters.productValue.value"
    }
  })], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-3"
  }, [_c('sygni-multi-select', {
    attrs: {
      "clear-on-select": false,
      "close-on-select": false,
      "display-values": true,
      "label": 'CURRENCY',
      "multiple": true,
      "options": _vm.usedCurrencies,
      "preserve-search": false,
      "searchable": true,
      "select-all": true
    },
    on: {
      "input": _vm.updateCurrencies,
      "toggleAll": _vm.toggleAllCurrencies
    },
    model: {
      value: _vm.selectedProductCurrencies,
      callback: function callback($$v) {
        _vm.selectedProductCurrencies = $$v;
      },
      expression: "selectedProductCurrencies"
    }
  })], 1), _c('div', {
    staticClass: "col-3"
  }, [_c('sygni-multi-select', {
    attrs: {
      "clear-on-select": false,
      "close-on-select": false,
      "display-values": true,
      "label": 'PRODUCT STATUS',
      "multiple": true,
      "options": _vm.productStatuses,
      "preserve-search": false,
      "searchable": true,
      "select-all": true
    },
    on: {
      "input": _vm.updateProductStatuses,
      "toggleAll": _vm.toggleAllProductStatuses
    },
    model: {
      value: _vm.selectedProductStatuses,
      callback: function callback($$v) {
        _vm.selectedProductStatuses = $$v;
      },
      expression: "selectedProductStatuses"
    }
  })], 1), _c('div', {
    staticClass: "col-3"
  }, [_c('sygni-select', {
    attrs: {
      "label": "SCHEDULE STATUS",
      "options": [{
        label: 'Generated',
        value: '1'
      }, {
        label: 'Not generated',
        value: '0'
      }]
    },
    model: {
      value: _vm.filters.repaymentScheduleGenerated.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.repaymentScheduleGenerated, "value", $$v);
      },
      expression: "filters.repaymentScheduleGenerated.value"
    }
  })], 1), _c('div', {
    staticClass: "col-3 show-results-button"
  }, [_c('sygni-rounded-button', {
    staticClass: "filled gn-secondary",
    attrs: {
      "id": "fe_search",
      "loading": _vm.isLoading,
      "selectable": false
    },
    on: {
      "click": function click($event) {
        return _vm.applyFilters();
      }
    }
  }, [_vm._v(" Show results (" + _vm._s(_vm.itemsCount) + ")")]), _c('div', {
    staticClass: "clear-all-button",
    on: {
      "click": function click($event) {
        return _vm.clearAll();
      }
    }
  }, [_vm._v("Clear all")])], 1)])])])]) : _vm._e(), _c('div', {
    class: ['marketplace-filters__toggle', !_vm.toggleFilters ? 'marketplace-filters__toggle--active' : ''],
    on: {
      "click": _vm.handleFiltersToggle
    }
  }, [_c('p', {
    staticClass: "marketplace-filters__toggle-option"
  }, [_vm._v(_vm._s(_vm.toggleText))]), _c('span', {
    staticClass: "marketplace-filters__toggle-arrow"
  }, [_vm._v(">>>")])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  })]);
}]

export { render, staticRenderFns }