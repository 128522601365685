











































































































import Vue from 'vue'
import Component from 'vue-class-component'
import GenproxModal from '@/components/layout/GenproxModal.vue';
import GenproxSteps from '@/components/layout/GenproxSteps.vue';
import SygniSelect from '@/components/inputs/SygniSelect.vue';
import GenproxInfobox from '@/components/layout/GenproxInfobox.vue';
import GenproxSimpleTable from '@/components/layout/GenproxSimpleTable.vue';
import { create, all } from 'mathjs'
const math = create(all);
import moment from 'moment'
import { requiredIf } from 'vuelidate/lib/validators';
import LoaderIcon from '@/assets/icons/heroicons/LoaderIcon.vue';
import CheckCircleIcon from '@/assets/icons/heroicons/CheckCircleIcon.vue';
import _ from 'lodash';
import Utils from '@/modules/shared/utils/utils';
import { Product } from '../store/types';
import ExclamationTriangleIcon from '@/assets/icons/heroicons/ExlamationTriangleIcon.vue';

Component.registerHooks(['validations'])
@Component({
  components: { GenproxModal, GenproxSteps, SygniSelect, GenproxInfobox, GenproxSimpleTable, LoaderIcon, CheckCircleIcon, ExclamationTriangleIcon },
})
export default class RecalculateSchedulesModal extends Vue {
  isLoading: boolean = false
  reRender: number = 0
  currentStep: number = 1
  loaderDots: string = ''
  loaderDotsInterval: any = null

  tableFields: any[] = [
    {
      key: 'productCode',
      label: 'Product code',
      type: 'text',
    },
    {
      key: 'interestBefore',
      label: 'Net interest (before)',
      align: 'right',
      type: 'number',
    },
    {
      key: 'interestAfter',
      label: 'Net interest (after)',
      align: 'right',
      type: 'number',
    },
    {
      key: 'difference',
      label: 'Difference',
      align: 'right',
      type: 'number',
    }
  ]
  tableItems: any[] = []

  errorTableFields: any[] = [
    {
      key: 'productCode',
      label: 'Product code',
      type: 'text',
    },
    {
      key: 'errorMessage',
      label: 'Message',
      type: 'text',
    },
  ]

  steps: any[] = [
    { id: 1, label: 'Choose period' }, 
    { id: 2, label: 'Confirm' },
    { id: 3, label: 'Recalculate' },
  ]

  schedulesData: { period: string, iborType: string } = {
    period: null,
    iborType: null,
  }

  repaymentsData: any = {}
  responseMapping: any = {}

  get errors() {
    let errors: any[] = []
    Object.keys(this.responseMapping)?.forEach((id: string) => {
      if (this.responseMapping[id]?.status === 'rejected') {
        errors.push(this.responseMapping[id])
      }
    })

    return errors || []
  }

  get schedulesAmount() {
    return Object.keys(this.repaymentsData)?.length || 0
  }

  get regeneratedSchedulesAmount() {
    return math.number(math.bignumber(math.subtract(math.bignumber(this.schedulesAmount), math.bignumber(this.errors?.length || 0))))
  }

  get optionString() {
    const periodName = this.quarterOptions?.find((el: any) => el?.value === this.schedulesData?.period)?.label || ''
    const iborLabel = this.iborTypes?.find((el: any) => el?.value === this.schedulesData?.iborType)?.label || this.schedulesData?.iborType || ''

    return `${periodName} (${iborLabel})`
  }

  get isLastStepCompleted() {
    return this.currentStep === this.steps?.length && !this.isLoading
  }

  get isDisabled() {
    if (this.currentStep === 2) {
      if (!this.tableItems?.length) {
        return true
      }

      if (this.tableItems?.length && this.tableItems?.find((item: any) => item?.loading)) {
        return true
      }
    }

    return false
  }

  get cancelText() {
    switch (this.currentStep) {
      case 1:
        return 'Cancel'
      case 2:
        return 'Go back'
      default:
        return ''
    }
  }

  get confirmText() {
    switch (this.currentStep) {
      case 2:
        return 'Confirm'
      case 3:
        return ''
      default:
        return 'Continue'
    }
  }

  get quarterOptions() {
    return [
      { label: moment()?.format("\\QQ YYYY"), value: `${moment().startOf('quarter')?.format('YYYY-MM-DD')}||${moment().endOf('quarter')?.format('YYYY-MM-DD')}` },
      { label: moment()?.subtract(1, 'Q')?.format("\\QQ YYYY"), value: `${moment()?.subtract(1, 'Q')?.startOf('quarter')?.format('YYYY-MM-DD')}||${moment()?.subtract(1, 'Q')?.endOf('quarter')?.format('YYYY-MM-DD')}` },
      { label: moment()?.subtract(2, 'Q')?.format("\\QQ YYYY"), value: `${moment()?.subtract(2, 'Q')?.startOf('quarter')?.format('YYYY-MM-DD')}||${moment()?.subtract(2, 'Q')?.endOf('quarter')?.format('YYYY-MM-DD')}` },
    ]
  }

  getDifference(valueBefore: number, valueAfter: number): number {
    return math.number(math.subtract(math.bignumber(valueAfter), math.bignumber(valueBefore)))
  }

  getDifferenceString(valueBefore: number, valueAfter: number) {
    const difference: number = this.getDifference(valueBefore, valueAfter)
    
    if (difference > 0) {
      return `+${this.$options.filters.numberFormat(difference, 2, true)}`
    }

    return `${this.$options.filters.numberFormat(difference, 2, true) || '0,00'}`
  }

  getPeriodPayload(period: string) {
    let splittedPeriod: any = period?.split('||')

    if (splittedPeriod?.length == 2) {
      return {
        start: splittedPeriod[0],
        to: splittedPeriod[1]
      }
    }
    
    throw new Error('Something went wrong. Please try again later.')
  }

  handleCancel() {
    if (this.currentStep > 1) {
      this.currentStep--

      if (this.currentStep === 1) {
        this.repaymentsData = {}
      }
    } else {
      this.handleClose()
    }
  }

  handleClose() {
    this.$emit('close')
  }

  async resolveObject(obj: any) {
    return Promise.all(
      Object.entries(obj).map(async ([k, v]) => [k, await v])
    ).then(Object.fromEntries);
  }

  getInterestNetSum(items: any[]) {
    return items.reduce((a: any, b: any) => {
      return math.number(math.bignumber(math.add(math.bignumber(a), math.bignumber(b?.interestAmountNet?.value || 0))));
    }, 0);
  }

  get interestBeforeTotal(): number {
    return this.tableItems?.reduce((a: any, b: any) => {
      return math.number(math.bignumber(math.add(math.bignumber(a), math.bignumber(b?.interestBefore || 0))));
    }, 0)
  }

  get interestAfterTotal(): number {
    return this.tableItems?.reduce((a: any, b: any) => {
      return math.number(math.bignumber(math.add(math.bignumber(a), math.bignumber(b?.interestAfter || 0))));
    }, 0)
  }

  get differenceTotal(): number {
    return this.tableItems?.reduce((a: any, b: any) => {
      return math.number(math.bignumber(math.add(math.bignumber(a), math.bignumber(this.getDifference(b?.interestBefore, b?.interestAfter) || 0))));
    }, 0)
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData']
  }

  isInSelectedQuarter(item: any) {
    const period = this.schedulesData?.period?.split('||')
    
    const from = period[0] || null
    const to = period[1] || null

    if (from && to) {
      const interestFrom = moment(item?.interestPeriodFrom, 'YYYY-MM-DD')
      const interestTo = moment(item?.interestPeriodTo, 'YYYY-MM-DD')

      const fromResult = interestFrom.isBetween(from, to, undefined, '[]')
      const toResult = interestTo.isBetween(from, to, undefined, '[]')

      if (fromResult && toResult) {
        return true
      }
    }

    return false
  }

  async handleConfirm() {
    this.$v?.$touch()

    if (!this.$v?.$error) {
      if (this.currentStep === this.steps?.length) {
        
        // last step
        this.handleClose()
        return

      } else {

        if (this.currentStep === 1) {
          this.isLoading = true

          try {
            const payload = { periodRange: this.getPeriodPayload(this.schedulesData?.period), iborType: this.schedulesData?.iborType }
            let tableItems: any = await this.$store.dispatch('products/getProductsWithSchedulesToRegenerate', payload)

            tableItems = tableItems?.map((item: { id: string, code: string }) => {
              return {
                id: item?.id,
                productCode: item?.code,
                interestBefore: 0,
                interestAfter: 0, 
                loading: true,
              }
            })

            this.tableItems = tableItems
            this.currentStep++

            const repaymentSchedules: any = {}

            for (let index = 0; index < this.tableItems?.length; index++) {
              const id = this.tableItems[index]?.id;
              repaymentSchedules[id] = this.$store.dispatch('products/generateRepaymentSchedule', id)
            }

            const response = await this.resolveObject(repaymentSchedules)
            
            Object.keys(response)?.forEach((id: string) => {
              const item = this.tableItems?.find((el: any) => el?.id === id)

              this.repaymentsData[id] = _.cloneDeep(response[id]?.items)?.map((el: any) => {
                delete el?.id

                el.interestAmountGross = el.interestAmountGross?.value ? math.number(math.round(math.bignumber(el.interestAmountGross.value), 2)) : 0
                el.interestTax = el.interestTax?.value ? math.number(math.round(math.bignumber(el.interestTax.value), 2)) : 0
                el.interestAmountNet = el.interestAmountNet?.value ? math.number(math.round(math.bignumber(el.interestAmountNet.value), 2)) : 0
                
                return el
              })

              const filteredActualItems = response[id]?.actualItems?.filter((item: any) => this.isInSelectedQuarter(item)) || []
              const filteredItems = response[id]?.items?.filter((item: any) => this.isInSelectedQuarter(item)) || []

              this.$set(item, 'interestBefore', this.getInterestNetSum(filteredActualItems))
              this.$set(item, 'interestAfter', this.getInterestNetSum(filteredItems))
              this.$set(item, 'loading', false)
              this.reRender++
            })

          } catch (e) {
            this.currentStep = 1
            this.tableItems = []

            const errorMessage = this.$options.filters.errorHandler(e)
            this.$notify({
              duration: 4500,
              type: 'error',
              title: 'Error',
              text: errorMessage
            })
          }

          this.isLoading = false
          return
        }

        if (this.currentStep === 2) {
          this.isLoading = true
          this.currentStep++

          try {
            const repaymentRequests: any[] = []

            // set cookie for 7 days
            const cookieValue = `{"contextId":"${this.activeUserData?.context?.id}","optionString":"${this.optionString}"}`
            Utils.setCookie('recalculationProcess', cookieValue, (7 * 24 * 60 * 60 * 1000))

            Object.keys(this.repaymentsData)?.forEach((productId: string) => {
              repaymentRequests.push(this.$store.dispatch('products/saveRepaymentSchedule', { productId, editedByUser: false, data: this.repaymentsData[productId] }))
            })

            const response = await Promise.allSettled(repaymentRequests)

            let i = 0
            Object.keys(this.repaymentsData)?.forEach((productId: string) => {
              const item = this.tableItems?.find((el: any) => el?.id === productId)
              this.$set(this.responseMapping, productId, { id: productId, productCode: item?.productCode, ...response[i] })
              i++
            })

            Utils.eraseCookie('recalculationProcess')

          } catch (e) {
            Utils.eraseCookie('recalculationProcess')
            this.currentStep = 2
            const errorMessage = this.$options.filters.errorHandler(e)
            this.$notify({
              duration: 4500,
              type: 'error',
              title: 'Error',
              text: errorMessage
            })
          }

          this.isLoading = false
          return
        }
      }
    }
  }

  get iborTypes() {
    const iborTypesDictionary: any = this.$store.getters['products/getIborTypes']
    const iborCurrencies: string[] = Object.keys(iborTypesDictionary || {})
    let iborTypes: any[] = []

    iborCurrencies?.forEach((currency: any) => {
      iborTypes = [...iborTypes, ...iborTypesDictionary[currency]]
    })

    return iborTypes || []
  }

  getProductSummaryLink(product: Product) {
    return `/${this.$route.path.includes('company') ? 'company' : 'fund'}/fundraising/product/summary/${product.id}/for-legal-entity`
  }

  async goToProductSummary(product: Product) {
    this.$store.commit('investors/clearAnnexingData');
    const routeData = this.$router.resolve({ path: this.getProductSummaryLink(product) });
    window.open(routeData.href, '_blank');
  }

  beforeMount() {
    this.loaderDotsInterval = setInterval(() => {
      if (this.loaderDots?.length === 3) {
        this.loaderDots = '.'
      } else {
        this.loaderDots = `${this.loaderDots}.`
      }
    }, 500)
  }

  beforeDestroy() {
    clearInterval(this.loaderDotsInterval)
  }

  validations() {
    return {
      schedulesData: {
        period: { required: requiredIf(() => this.currentStep === 1) },
        iborType: { required: requiredIf(() => this.currentStep === 1) },
      }
    }
  }

  mounted() {
    if (this.iborTypes?.length === 0) {
      this.$store.dispatch('products/getIborTypes')
    }
  }
}
