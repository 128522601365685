var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    key: "gn-dropdown-menu-".concat(_vm.reRender),
    class: ['gn-dropdown-menu', "gn-dropdown-menu--".concat(_vm.size)],
    attrs: {
      "id": "".concat(_vm.id, "-").concat(_vm.reRender)
    }
  }, [_vm._t("default", function () {
    return [_vm._v(_vm._s(_vm.label))];
  }), _c('b-tooltip', {
    attrs: {
      "placement": _vm.placement,
      "custom-class": "plain",
      "boundary": "viewport",
      "target": "".concat(_vm.id, "-").concat(_vm.reRender)
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
      }
    }
  }, [_c('div', {
    class: ['gn-dropdown-menu', _vm.additionalDropdownClass]
  }, [_c('div', {
    staticClass: "gn-dropdown-menu__wrapper"
  }, [_c('simplebar', {
    staticClass: "gn-dropdown-menu__inner"
  }, [_c('div', {
    staticClass: "gn-dropdown-menu__items"
  }, _vm._l(_vm.options, function (option) {
    return _c('div', {
      key: option === null || option === void 0 ? void 0 : option.value,
      class: ['gn-dropdown-menu__link', (option === null || option === void 0 ? void 0 : option.value) === _vm.activeOption ? 'active' : '', option !== null && option !== void 0 && option.disabled ? 'disabled' : ''],
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.handleInput(option === null || option === void 0 ? void 0 : option.value, option === null || option === void 0 ? void 0 : option.disabled);
        }
      }
    }, [_vm._v(_vm._s(option === null || option === void 0 ? void 0 : option.label))]);
  }), 0)])], 1)])])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }