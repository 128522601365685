var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "gn-steps"
  }, [_c('simplebar', {
    staticClass: "gn-steps__inner"
  }, [_c('div', {
    staticClass: "gn-steps__container"
  }, _vm._l(_vm.steps, function (step) {
    return _c('div', {
      key: step.id,
      class: ['gn-steps__block', _vm.getStepAdditionalClassName(step.id)],
      on: {
        "click": function click($event) {
          return _vm.handleStep(step);
        }
      }
    }, [_c('div', {
      staticClass: "gn-steps__counter"
    }, [_c('span', {
      staticClass: "gn-steps__counter-amount"
    }, [_vm._v(_vm._s(step === null || step === void 0 ? void 0 : step.id))]), _c('div', {
      staticClass: "gn-steps__counter-icon"
    }, [_c('CheckIcon')], 1)]), _c('p', {
      staticClass: "gn-steps__label"
    }, [_vm._v(_vm._s(step === null || step === void 0 ? void 0 : step.label))])]);
  }), 0)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }