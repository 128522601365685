


























import _ from 'lodash';
import Vue from 'vue';
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator';
import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';

@Component({
  components: { simplebar }
})
export default class GenproxInfobox extends Vue {
  @Prop({ default: [] }) tableFields: any[]
  @Prop({ default: [] }) tableItems: any[]

  items: any[] = []

  mounted() {
    if (this.tableItems?.length) {
      this.items = _.cloneDeep(this.tableItems)
    }
  }
}
