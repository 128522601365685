var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('GenproxModal', {
    staticClass: "recalculate-modal",
    attrs: {
      "size": "full",
      "isLoading": _vm.isLoading && _vm.currentStep !== 3,
      "header": "Recalculate schedules",
      "description": "Adjust financial schedules based on the latest IBOR rate.",
      "cancelText": _vm.cancelText,
      "confirmText": _vm.confirmText,
      "smallerActionsPadding": _vm.currentStep === 1 ? true : false,
      "disabled": _vm.isDisabled
    },
    on: {
      "cancel": _vm.handleCancel,
      "close": _vm.handleClose,
      "confirm": _vm.handleConfirm
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        var _vm$$v, _vm$$v$schedulesData, _vm$$v2, _vm$$v2$schedulesData, _vm$tableItems, _vm$errors, _vm$errors2, _vm$errors3, _vm$errors4, _vm$errors5, _vm$errors6, _vm$errors7, _vm$errors8;

        return [_c('GenproxSteps', {
          attrs: {
            "currentStep": _vm.currentStep,
            "steps": _vm.steps,
            "isLastStepCompleted": _vm.isLastStepCompleted
          }
        }), _vm.currentStep === 1 ? [_c('GenproxInfobox', {
          attrs: {
            "title": "Important notice",
            "description": "This change will affect all schedules for products tied to the specified IBOR rate."
          }
        }), _c('div', {
          staticClass: "form"
        }, [_c('div', {
          staticClass: "form__col"
        }, [_c('sygni-select', {
          attrs: {
            "label": "Select Quarter",
            "options": _vm.quarterOptions,
            "legacyStyling": false,
            "validation": (_vm$$v = _vm.$v) === null || _vm$$v === void 0 ? void 0 : (_vm$$v$schedulesData = _vm$$v.schedulesData) === null || _vm$$v$schedulesData === void 0 ? void 0 : _vm$$v$schedulesData.period
          },
          model: {
            value: _vm.schedulesData.period,
            callback: function callback($$v) {
              _vm.$set(_vm.schedulesData, "period", $$v);
            },
            expression: "schedulesData.period"
          }
        })], 1), _c('div', {
          staticClass: "form__col"
        }, [_c('sygni-select', {
          attrs: {
            "label": "Select IBOR Type",
            "legacyStyling": false,
            "options": _vm.iborTypes,
            "validation": (_vm$$v2 = _vm.$v) === null || _vm$$v2 === void 0 ? void 0 : (_vm$$v2$schedulesData = _vm$$v2.schedulesData) === null || _vm$$v2$schedulesData === void 0 ? void 0 : _vm$$v2$schedulesData.iborType
          },
          model: {
            value: _vm.schedulesData.iborType,
            callback: function callback($$v) {
              _vm.$set(_vm.schedulesData, "iborType", $$v);
            },
            expression: "schedulesData.iborType"
          }
        })], 1)])] : _vm._e(), _vm.currentStep === 2 ? [_c('div', {
          staticClass: "recalculate-modal__summary"
        }, [_c('p', {
          staticClass: "recalculate-modal__title"
        }, [_vm._v("Summary")]), _c('p', {
          staticClass: "recalculate-modal__desc"
        }, [_vm._v(" This action will recalculate "), _c('strong', [_vm._v(_vm._s(((_vm$tableItems = _vm.tableItems) === null || _vm$tableItems === void 0 ? void 0 : _vm$tableItems.length) || 0))]), _vm._v(" schedules. "), _c('br'), _vm._v(" Please review the data below carefully to ensure accuracy before proceeding. ")])]), _c('GenproxSimpleTable', {
          key: "recalculation-table-".concat(_vm.reRender),
          attrs: {
            "tableFields": _vm.tableFields,
            "tableItems": _vm.tableItems
          },
          scopedSlots: _vm._u([{
            key: "tbodyCol.productCode",
            fn: function fn(_ref) {
              var item = _ref.item;
              return [_c('a', {
                staticClass: "code",
                attrs: {
                  "href": _vm.getProductSummaryLink(item)
                },
                on: {
                  "click": function click($event) {
                    $event.preventDefault();
                    return _vm.goToProductSummary(item);
                  }
                }
              }, [_vm._v(_vm._s((item === null || item === void 0 ? void 0 : item.productCode) || 'NONE'))])];
            }
          }, {
            key: "tbodyCol.interestAfter",
            fn: function fn(_ref2) {
              var item = _ref2.item;
              return [_c('div', {
                staticClass: "interest-after"
              }, [_c('span', [_vm._v(_vm._s(_vm._f("numberFormat")(item === null || item === void 0 ? void 0 : item.interestAfter)))])])];
            }
          }, {
            key: "tbodyCol.difference",
            fn: function fn(_ref3) {
              var item = _ref3.item;
              return [_c('div', {
                staticClass: "interest-after"
              }, [_c('span', {
                class: ['difference', _vm.getDifference(item === null || item === void 0 ? void 0 : item.interestBefore, item === null || item === void 0 ? void 0 : item.interestAfter) > 0 ? 'text-green' : 'text-red']
              }, [_vm._v(_vm._s(_vm.getDifferenceString(item === null || item === void 0 ? void 0 : item.interestBefore, item === null || item === void 0 ? void 0 : item.interestAfter)))])])];
            }
          }, {
            key: "tfoot",
            fn: function fn() {
              return [_c('tfoot', [_c('tr', [_c('td', [_c('span', {
                staticClass: "summary-label"
              }, [_vm._v("Summary")])]), _c('td', {
                staticClass: "right"
              }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.interestBeforeTotal)))]), _c('td', {
                staticClass: "right"
              }, [_c('div', {
                staticClass: "interest-after"
              }, [_c('span', [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.interestAfterTotal)))])])]), _c('td', [_c('div', {
                staticClass: "interest-after"
              }, [_c('span', {
                class: ['difference', _vm.differenceTotal > 0 ? 'text-green' : 'text-red']
              }, [_vm._v(_vm._s(_vm.getDifferenceString(_vm.interestBeforeTotal, _vm.interestAfterTotal)))])])])])])];
            },
            proxy: true
          }], null, false, 136793861)
        })] : _vm._e(), _vm.currentStep === 3 ? [_vm.isLoading ? _c('GenproxInfobox', {
          attrs: {
            "type": "warning",
            "title": "Please wait".concat(_vm.loaderDots)
          },
          scopedSlots: _vm._u([{
            key: "description",
            fn: function fn() {
              return [_vm._v(" The recalculation of schedules is in progress. "), _c('br'), _c('strong', [_vm._v("Please do not close this window")]), _vm._v(", as it may interrupt the process. ")];
            },
            proxy: true
          }], null, false, 2556698249)
        }) : _vm._e(), _c('div', {
          class: ['state-progress', !_vm.isLoading && !((_vm$errors = _vm.errors) !== null && _vm$errors !== void 0 && _vm$errors.length) ? 'state-progress--completed' : '', (_vm$errors2 = _vm.errors) !== null && _vm$errors2 !== void 0 && _vm$errors2.length ? 'state-progress--error' : '']
        }, [_c('div', {
          staticClass: "state-progress__icon"
        }, [_vm.isLoading ? _c('LoaderIcon') : [!((_vm$errors3 = _vm.errors) !== null && _vm$errors3 !== void 0 && _vm$errors3.length) ? _c('CheckCircleIcon') : _c('ExclamationTriangleIcon')]], 2), _c('p', {
          staticClass: "state-progress__text"
        }, [_vm.isLoading ? [_vm._v("Recalculating in progress" + _vm._s(_vm.loaderDots))] : [!((_vm$errors4 = _vm.errors) !== null && _vm$errors4 !== void 0 && _vm$errors4.length) ? [_vm._v("Done! All data has been saved.")] : [_vm._v("There were some errors with your submission")]]], 2), !_vm.isLoading ? _c('p', {
          staticClass: "state-progress__desc"
        }, [_c('strong', [_vm._v(_vm._s(_vm.regeneratedSchedulesAmount))]), _vm._v(" " + _vm._s(_vm.regeneratedSchedulesAmount === 1 ? 'schedule' : 'schedules') + " " + _vm._s(_vm.regeneratedSchedulesAmount === 1 ? 'was' : 'were') + " recalculated "), _c('strong', [_vm._v("successfully")]), _vm._v(". "), (_vm$errors5 = _vm.errors) !== null && _vm$errors5 !== void 0 && _vm$errors5.length ? [_c('br'), _c('strong', [_vm._v(_vm._s((_vm$errors6 = _vm.errors) === null || _vm$errors6 === void 0 ? void 0 : _vm$errors6.length))]), _vm._v(" " + _vm._s(((_vm$errors7 = _vm.errors) === null || _vm$errors7 === void 0 ? void 0 : _vm$errors7.length) === 1 ? 'recalculation' : 'recalculations') + " "), _c('strong', [_vm._v("failed")]), _vm._v(". See the details below. ")] : _vm._e()], 2) : _vm._e()]), (_vm$errors8 = _vm.errors) !== null && _vm$errors8 !== void 0 && _vm$errors8.length ? [_c('div', {
          staticClass: "errors"
        }, [_c('p', {
          staticClass: "recalculate-modal__heading"
        }, [_vm._v("Error details")]), _c('GenproxSimpleTable', {
          key: "recalculation-error-table-".concat(_vm.reRender),
          attrs: {
            "tableFields": _vm.errorTableFields,
            "tableItems": _vm.errors
          },
          scopedSlots: _vm._u([{
            key: "tbodyCol.productCode",
            fn: function fn(_ref4) {
              var item = _ref4.item;
              return [_c('a', {
                staticClass: "code",
                attrs: {
                  "href": _vm.getProductSummaryLink(item)
                },
                on: {
                  "click": function click($event) {
                    $event.preventDefault();
                    return _vm.goToProductSummary(item);
                  }
                }
              }, [_vm._v(_vm._s((item === null || item === void 0 ? void 0 : item.productCode) || 'NONE'))])];
            }
          }, {
            key: "tbodyCol.errorMessage",
            fn: function fn(_ref5) {
              var _item$reason;

              var item = _ref5.item;
              return [_c('span', [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : (_item$reason = item.reason) === null || _item$reason === void 0 ? void 0 : _item$reason.message))])];
            }
          }], null, false, 452649253)
        })], 1)] : _vm._e()] : _vm._e()];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }