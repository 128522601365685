var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['gn-infobox', "gn-infobox--".concat(_vm.type), _vm.rejectable ? 'gn-infobox--rejectable' : '']
  }, [_c('div', {
    staticClass: "gn-infobox__inner"
  }, [_vm.rejectable ? _c('div', {
    staticClass: "gn-infobox__close",
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('XMarkIcon')], 1) : _vm._e(), _c('div', {
    staticClass: "gn-infobox__icon"
  }, [_vm.type === 'info' ? _c('InformationCircleIcon') : _vm._e(), _vm.type === 'warning' ? _c('ExclamationTriangleIcon') : _vm._e()], 1), _c('div', {
    staticClass: "gn-infobox__body"
  }, [_c('div', {
    staticClass: "gn-infobox__title"
  }, [_vm._t("title", function () {
    return [_vm._v(_vm._s(_vm.title))];
  })], 2), _c('div', {
    staticClass: "gn-infobox__description"
  }, [_vm._t("description", function () {
    return [_vm._v(_vm._s(_vm.description))];
  })], 2)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }