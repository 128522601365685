

















import Vue from 'vue'
import Component from 'vue-class-component'
import {Prop, Watch} from "vue-property-decorator";
import { SelectOption } from '@/store/types';
import simplebar from 'simplebar-vue';
import { BTooltip } from 'bootstrap-vue';
import 'simplebar/dist/simplebar.min.css';

@Component({
  components: { simplebar, BTooltip },
})
export default class GenproxDropdownMenu extends Vue {
  @Prop({ default: '' }) additionalDropdownClass: string
  @Prop({ default: 'normal' }) size: 'normal' | 'small'
  @Prop({ default: 'bottomleft' }) placement: string
  @Prop({ default: [] }) options!: SelectOption[];
  @Prop({ default: null }) activeOption!: String;
  @Prop({ default: null }) id: string;
  @Prop() label!: String;
  
  reRender: number = 0;

  handleInput(value: string, isDisabled: boolean = false): void {
    if (isDisabled !== undefined && isDisabled) return;

    this.$emit('input', value)
  }

  @Watch('options', { deep: true }) onMenusChange() {
    this.reRender++
  }
}
