var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "gn-simple-table"
  }, [_c('simplebar', {
    staticClass: "gn-simple-table__container"
  }, [_c('table', {
    staticClass: "gn-simple-table__el"
  }, [_c('thead', [_c('tr', _vm._l(_vm.tableFields, function (item, index) {
    return _c('th', {
      key: "theadCol-".concat(index),
      class: (item === null || item === void 0 ? void 0 : item.align) || 'center'
    }, [_vm._v(_vm._s((item === null || item === void 0 ? void 0 : item.label) || (item === null || item === void 0 ? void 0 : item.key)))]);
  }), 0)]), _c('tbody', _vm._l(_vm.items, function (row, index) {
    return _c('tr', {
      key: "tbodyRow-".concat(index),
      class: [row !== null && row !== void 0 && row.loading ? 'loading' : '']
    }, _vm._l(_vm.tableFields, function (item, i) {
      return _c('td', {
        key: "tbodyRow-".concat(index, "-col-").concat(item === null || item === void 0 ? void 0 : item.key, "-").concat(i),
        class: (item === null || item === void 0 ? void 0 : item.align) || 'center'
      }, [!(row !== null && row !== void 0 && row.loading) ? _vm._t("tbodyCol.".concat(item === null || item === void 0 ? void 0 : item.key), function () {
        return [(item === null || item === void 0 ? void 0 : item.type) === 'number' ? [_vm._v(_vm._s(_vm._f("numberFormat")(row[item === null || item === void 0 ? void 0 : item.key])))] : [_vm._v(_vm._s(row[item === null || item === void 0 ? void 0 : item.key]))]];
      }, {
        "item": row
      }) : [_vm._v("loading...")]], 2);
    }), 0);
  }), 0), _vm._t("tfoot")], 2)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }