






















import { ExclamationTriangleIcon, InformationCircleIcon, XMarkIcon } from '@/assets/icons/heroicons/heroicons';
import Vue from 'vue';
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator';

@Component({
  components: { InformationCircleIcon, ExclamationTriangleIcon, XMarkIcon }
})
export default class GenproxInfobox extends Vue {
  @Prop({ default: 'info' }) type: 'info' | 'warning'
  @Prop({ default: false }) rejectable: boolean;
  @Prop({ default: null }) title: string
  @Prop({ default: null }) description: string
}
